(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc controller
    * @name LoginController
    * @param {service} $timeout settimeout de angular.
    * @param {service} LoginService Obtiene y gestiona el Login de usuario y agencias.   
    * @param {service} UserService Obtiene y gestiona el Usuario desde la REST Api.
    * @description
    * Controlador para la gestionar login de agencias y login de usuarios(se utiliza en Login.ascx).
    */
    angular
    .module('app')
    .controller('LoginController', LoginController);

    LoginController.$inject = ["$timeout","LoginService","UserService"];

    function LoginController($timeout,LoginService,UserService)
    {
        var lg = this;

        /**
        * @property {Object} agencies Objeto para guardar los datos de las agencias.
        */
        lg.agencies = {
            code:"",
            user:"",
            password:""
        };
        /**
       * @property {String} username Usuario del login para clientes.
       */
        lg.username = "";
        /**
       * @property {String} password Password del login para clientes.
       */
        lg.password = "";
        /**
       * @property {Bool} remember Recordar login.
       */
        lg.remember = "false";
        lg.user =  UserService;

        lg.pKeepWaitScreenOpen;
        /*        
       * Marca un formulario como enviado.
       * @memberof LoginController
       * @function setSubmitted
       */
        lg.setSubmitted = function(form)
        {
            form.$setSubmitted();
            return true;
        }
        /*        
        *Login para agencias 
        * @memberof LoginController
        * @function getH10LoginAgencia
        */
        lg.getH10LoginAgencia= function(pKeepWaitScreenOpen) {
            closeSession(true);
            writeLog("getH10LoginAgencia");
            writeLog(lg.agencies);

            var idCodigoEmpresa = lg.agencies.code;
            var idLog = lg.agencies.user;
            var idPass = lg.agencies.password;

            if (typeof (pKeepWaitScreenOpen) != 'undefined' && pKeepWaitScreenOpen == true) {
                idCodigoEmpresa = "";
                idLog = "";
                idPass = "";
            }

            writeLog(idCodigoEmpresa + idLog + idPass);
            if (idLog == "" || idPass == "" || idCodigoEmpresa == "")
                return false;

            showWaitScreen();

            //H10Login(idLog, idPass, "Login");
            LoginService.H10LoginAgencia(idCodigoEmpresa, idLog, idPass, "LoginAgencia").then(function (data) {
                if (data.errorProduced == "" || data.errorProduced == null) {
                    $timeout(function () {
                        location.reload(true);
                    }, 200);
                }
                writeLog("/getH10LoginAgencia");
                if (!pKeepWaitScreenOpen) {
                    closeWaitScreen();
                }

            }, function () {
                if (!pKeepWaitScreenOpen) {
                    closeWaitScreen();
                }
            });
            
           return true;
        }

        lg.getH10Login = function()
        {
            writeLog("LOGIN ");
            lg.login();
        }
        /*
        * Hace Login del Usuario.
        * @memberof LoginController
        * @function login
        */
    	lg.login = function()
        {
          writeLog(lg.remember);
          LoginService.getH10Login(lg.remember);
          LoginService.H10Login(lg.username, lg.password, "Login").then(function (retData) {
              if (retData.errorProduced == undefined || retData.errorProduced == "") {
                  $timeout(function () {
                      location.reload(true);
                  }, 200);
              }
          });
    	  //cerramos collapse para evitar cambio anterior a la carga
          //$('#collapseLog').collapse('hide');
          //$('#btn-log').removeClass('active');
    	}
        /*
        * Hace Logout del Usuario.
        * @memberof LoginController
        * @function logout
        */
        lg.logout = function()
        {
            LoginService.closeSession();
            $('#collapseLog').collapse('hide');
            $('#btn-log').removeClass('active');
        }
        //No se implemento por problemas tecnicos.
        lg.LoginFacebook = function()
        {
                writeLog("Facebook");
                var oauth_url = 'https://www.facebook.com/dialog/oauth/';
                oauth_url += '?client_id=1448245005422092';
                oauth_url += '&scope=email'
                window.top.location = oauth_url;
        }
        //No se implemento por problemas tecnicos.
        lg.LoginGoogle = function()
        {
            writeLog("GOOGLE");
            window.top.location = "https://accounts.google.com/signin/oauth/oauthchooseaccount?client_id=814049400835-jlebjs76td6t2dfgidinnmkeromftfds.apps.googleusercontent.com";
        }
        //No se implemento por problemas tecnicos.
        lg.LoginTwitter = function()
        {
            writeLog("Twitter");
            window.top.location = "https://api.twitter.com/oauth/authorize?oauth_token=Z9u6Beel2fpGwsDrIv7PWw69y";
        }

        //Miramos existen cookies de Usuario 
        UserService.H10LogedUser();
        UserService.H10LogedAgencia();
    	writeLog("login LOADED");
    }



})();