(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc controller
    * @name InstagramController
    * @param {service} $timeout settimeout de angular.
    * @param {service} VariableService Gestiona las Variables del sistema.
    * @param {service} InstagramService Obtiene y gestiona de Instagram desde la REST Api.
    * @description
    * Controlador del webPart HomeInstagram 
     */

    angular
    .module('app')
    .controller('InstagramController', InstagramController);

    InstagramController.$inject = ["$timeout","VariableService","InstagramService"];

    function InstagramController($timeout,VariableService,InstagramService)
    {
        //Carga de servicios 
        var instagram = this;
        /**
        * @property {Array} posts Coleccion de las fotos de Instagram.
        */
    	instagram.posts = [] ;
    	instagram.info = InstagramService;
        /**
        * @property {Bool} show Valora si se muestra el muro de Instagram 
        */
    	instagram.show = false;
        //Tama�o de las imagenes que el navegador a logrado cargar, se incrementa en la funcion img.load .
        var length = 0;
        //Imagenes a cargar
        var limitImages = 8;
        /*
        * Limpiador de Imagenes para limitar su uso a 8 imagenes
        * @memberof InstagramController
        * @function clearImgs
        */
        instagram.clearImgs = function()
        {
                //if (instagram.posts.length <= length)
                
                if (length > (instagram.posts.length-1) )
                {
                    angular.forEach(instagram.posts, function (value, key) {
                        
                        if(!instagram.posts[key].visible)
                        {
                            instagram.posts.splice(key, 1);
                            $('#h10exp .swiper-slide').height($('#h10exp .swiper-slide').width());
                        }
                    });

                } else {
                    ExpInst = true;
                    $timeout(function () {
                        writeLog("Correcci�n de altura");
                        $('#h10exp .swiper-slide').height($('#h10exp .swiper-slide').width());
                    },250);
                    return;
                }
                    
        }
        /*
        * Funcion que recoge los datos de instagram.
        * @memberof InstagramController
        * @function getPost
        */
    	InstagramService.getPost().then(function(data){

    		instagram.posts = data.posts;
            //Ponemos todos los elementos como invisibles
            angular.forEach(instagram.posts,function(value,key){
                instagram.posts[key].visible = 0;
                //Evento para cada imagen, una vez cargada por el navegador se aplica visibilidad = 1
                //Solucion para evitar imagenes en blanco .
                var img = document.createElement("img");
                instagram.posts[key].imagelink = value.imagelink + "?RenditionID=61";
                img.src = instagram.posts[key].imagelink;
                //La imagen carga perfectamente.
                        img.onload = function()
                        {
                            var total = 0;
                            angular.forEach(instagram.posts, function (value, key) {
                                if (instagram.posts[key].visible)
                                    total++;
                            });
                                
                            if (total < limitImages) {
                                instagram.posts[key].visible = 1;
                            }
                            length++;
                            instagram.clearImgs();
                        }
                        // error, la imagen no es posible de cargar
                        img.onerror = function()
                        {
                            length++;
                            instagram.clearImgs();
                        }
            });
            instagram.show = true;

        });
        //Validaci�n de que esta activo el controlador.
    	writeLog("Instagram is Loaded");
    }



})();