(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc filter
    * @name moment
    * @description
    * Filtro que pone el primer caracter de un string en mayusculas.
    */

    angular.module('app').filter("moment", function () {
    	return function (input,format){
        	if(input) { 
           	    input = moment(input,"DD/MM/YYYY").format(format);
        	}
        	return input; 
    	}    
	});

})();