(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc controller
    * @name FooterController
    * @param {service} $timeout settimeout de angular.
    * @param {service} FooterDestinations Obtiene y gestiona los Telefonos desde la REST Api.
    * @description
    * Controlador de la seccion Footer( se utiliza en FooterDestinations.ascx).
    */

    angular
    .module('app')
    .controller('FooterController', FooterController);

    FooterController.$inject = ["$timeout","FooterDestinations"];

    function FooterController($timeout,FooterDestinations)
    {
        var vm = this;
        /**
        * @property {Array} footerdestinations Coleccion de las destinaciones del footer.
        */
    	vm.footerdestinations = [];
        /**
        * Obtiene las destinaciones del footer.
        * @memberof FooterController
        * @function getDestinosFooter
        */
    	FooterDestinations.getDestinosFooter().then(function(data)
    	{
    		vm.footerdestinations = data;
    		writeLog(vm.footerdestinations);
    	});

    	writeLog("footer LOADED");
    }
})();