(function(){
    'use strict';
    /**
* @memberof app
* @ngdoc directive
* @name lazy
* @param {service} $timeout settimeout de angular.
* @param {service} $parse Compilador de AngularJS.
* @param {service} $rootScope Scope root del controlador superior.
* @description
* DEPRECATED
*/
    angular
    .module('app').directive('rooms', rooms);

    rooms.$inject = ['$timeout', '$parse','$rootScope'];

    function rooms($timeout, $parse,$rootScope){
        return {
            restrict: 'A',
            require: 'ngModel',
            templateUrl: 'views/rooms.html',
            link: function (scope, element, $attrs, ngModel,rootScope) {
                return $timeout(function () {
                    var value = $attrs.value;
                    var $element = $(element);

                  
                });
            }
        };
    }


})();