(function(){
    'use strict';

    angular
    .module('app')
    .factory('StorageService', StorageService);

    StorageService.$inject = [];
    /**
     * Metodos para sessionStorage
     */
    function StorageService()
    {
    	/*
    	* Ampliamos la clase Storage de JS
    	*/
    	if (typeof Storage.prototype.setObject != 'function') {
    		Storage.prototype.setObject = function (key, value) {
        		this.setItem(key, JSON.stringify(value));
    		};
		}

		if (typeof Storage.prototype.getObject != 'function') {
    		Storage.prototype.getObject = function (key) {
        	var value = this.getItem(key);

        		if (value != null && value.length == 26 && value.substring(24, 25) == "Z" && value.substring(5, 6) == "-" && value.substring(8, 9) == "-" && value.substring(11, 12) == "T") {
            		value = value.substring(1, 25);
            		return new Date(value);
        		}
        		return value && JSON.parse(value);
    		};
		}

    	var st = {};
        
        /**
         * Se crea un valor
         */
    	st.setObject = function(name,value)
    	{
    		localStorage.setObject(name,value)
    		return localStorage.getObject(name);
    	}
    	/*
    	 * Devuelve un valor del objeto localStorage
    	 */
    	st.getObject = function(name,def)
    	{
		    if (localStorage.getObject(name) === null) {
		    	return def;
		    }
		    return localStorage.getObject(name);
    	}
		/**
		*	Valida que es un objeto el valor que llega .
		*/
		st.isObject = function(val) {
    		return (typeof val === 'object');
		}
        /*
         * Devuelve el objeto ls como funcionalidad extra de localStorage
         */
    	return st ;
    }

})();
