(function(){
    'use strict';
    /**
   * @memberof app
   * @ngdoc controller
   * @name NewsletterController
   * @param {service} $timeout settimeout de angular.
   * @param {service} VariableService Gestiona las Variables del sistema.
   * @description
   * Controlador Webpart HomeNewsletter.
   */

    angular
    .module('app')
    .factory('homewebappservice', homewebappservice);

    homewebappservice.$inject = ["$timeout", "$http", "VariableService"];

    function homewebappservice($timeout, $http, VariableService)
    {
        var homewebapp = {};

        return homewebapp;
    }

})();