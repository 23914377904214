 (function(){
    'use strict';

     /**
     * @memberof app
     * @ngdoc directive
     * @name selectBoxit
     * @param {service} $timeout settimeout de angular.
     * @param {service} $parse Compilador de AngularJS.
     * @description
     * Implementacion del plugin de jQuery select Box it para angularJS.
     */
    angular
    .module('app').directive('selectBoxit', selectBoxit);

    selectBoxit.$inject = ['$timeout', '$parse','$rootScope'];

    function selectBoxit($timeout, $parse,$rootScope){
        return {
            restrict: 'A',
            require: 'ngModel',
            
            link: function (scope, element, $attrs, ngModel,rootScope) {
                return $timeout(function () {
                    var value = $attrs.value;
                    var $element = $(element);
                    $element.selectBoxIt();

                    
                    /*scope.$watch('neumaticos', function (newValue) {
                        $timeout(function () {$element.data("selectBox-selectBoxIt").refresh();});
                    });*/
                    
                    scope.$watch($attrs.ngModel, function (newValue) {
                        $timeout(function () {$element.data("selectBox-selectBoxIt").refresh();},10);
                    });

                    scope.$on('reloadSelectBoxit',function(event,arg){
                        //writeLog($element.attr('id'));
                        if($element.attr('id')==arg.id)
                            $timeout(function () {
                                $element.data("selectBox-selectBoxIt").refresh();
                                writeLog("refrescado");
                            },10);

                    });

                    // If the iCheck control changes, update the model.
                    $element.on('change', function (event) {
                        var reload = null;
                          scope.$apply(function () {
                                ngModel.$setViewValue($element.val());
                                if($attrs.reload)
                                {
                                    reload = eval($attrs.reload);                                    
                                    for (var i = 0; i < reload.length; i++) {
                                        jQuery("#"+reload[i]).data("selectBox-selectBoxIt").refresh();
                                    }
                                }

                                //$rootScope.$broadcast('reload',{el:$element});
                            });
                          this.blur();
                    });

                });
            }
        };
    }


})();