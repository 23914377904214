(function(){
    'use strict';

    angular
    .module('app')
    .factory('DateService', DateService);

    DateService.$inject = ["LocalStorageService","$http","$q"];
    /* 
     * Funciones para la creaci�n de fechas, se usa moment JS
     */
    function DateService(LocalStorageService, $http, $q)
    {
    	
    	var d = {};
    	//SessionStorage de fechas H10hotels 
    	//DBegin
		//DEnd4
    	d.format = "DD/MM/YYYY";
        d.currentCountry = null;
    	d.init =  function()
    	{
            var currentCountry = "ESP";

            if (sessionStorage.getItem("CountryCliente") === null) {
                    d.getCountry().then(function(data)
                    {
                            if (data.ip != null) {
                                sessionStorage.setObject("CountryCliente", data.country);
                                sessionStorage.setObject("IpCliente", data.ip);
                                currentCountry = data.country;
                            }
                            else {
                                currentCountry = "ESP";
                            }
                    },function(data){
                        currentCountry = data;
                    });
            }
            else {
                currentCountry = sessionStorage.getItem("CountryCliente");
                currentCountry = currentCountry.replace(/\"/g, "");
            }
            if (document.location.host.indexOf("ocean") != -1 && document.location.pathname.split('/').slice(1, 2).join() == "en")
                currentCountry = "USA";
            d.currentCountry = currentCountry;
    	}

        d.getCountry = function() {
            var deferred = $q.defer();
            var countryData = null;
            if (sessionStorage.getItem("CountryCliente") === null) {
               var ajaxUrl = "https://ipinfo.io/?token=be0af1e43eb9eb";
               var data;
               $http.post(ajaxUrl).then(function(data){
                    if (window.logEnabled) {
                         writeLog(data);
                    }
                    deferred.resolve( data);
                },function(){
                    deferred.reject("ESP");
                });
            }else {
                    countryData = sessionStorage.getItem("CountryCliente");
                    countryData = countryData.replace(/\"/g, "");
                    deferred.reject(countryData);
           }
           return deferred.promise;
        }

        d.init();
        /**
         * Transforma una fecha dado un formato
         */
    	d.toDate = function(date,format)
    	{
    		if (!format) 
				format = d.format;
			return moment(date,format).toDate();
    	}
        /*
         * Se devuelve el nombre del mes para un idioma. 
         */
		d.getMonthsByLanguage = function()
		{
			var arr = [];
			for (var i = 0; i < 12; i++) {
				arr.push(moment().subtract(0, "month").startOf("month").format('MMMM'));
			}
			return arr;
		}
        /*
         * Se devuelve el nombre de un mes para un idioma en formato reducido
         */
		d.getMonthReduced = function(date,format)
		{
			if (!format) 
				format = d.format;
			return moment(date,format).format('MMM');
		}
        /**
         * Se devuelve el nombre de un dia reducido
         */
		d.getDayReduced = function(date,format)
		{
			if (!format) 
				format = d.format;
			return moment(date,format).format('ddd');
		}
        /**
         * Se devuelve un dia para una fecha determinada.
         */
		d.getDay = function(date,format)
		{
			if (!format) 
				format = d.format;
			return moment(date,format).format('D');
		}
        /**
        * devuelve una diferencia de fechas incluye el dia
        */
        d.diff = function(date1,date2)
        {
            var a = moment(date1,d.format);
            var b = moment(date2,d.format);
            return b.diff(a, 'days') +1;
            
        }


        /**
         * Se devuelve el Objeto de funciones
         */
    	return d ;
    }

})();
