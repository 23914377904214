(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc controller
    * @name NewsletterController
    * @param {service} $timeout settimeout de angular.
    * @param {service} VariableService Gestiona las Variables del sistema.
    * @description
    * Controlador Webpart HomeNewsletter.
    */

    angular
    .module('app')
    .controller('NewsletterController', NewsletterController);

    NewsletterController.$inject = ["$timeout","VariableService","NewsletterService"];

    function NewsletterController($timeout,VariableService,NewsletterService)
    {
        var nc = this;
        /**
        * @property {String} country Campo Pais.
        */
        nc.country = "";
        /**
        * @property {String} name Campo Nombre.
        */
        nc.name = "";
        /**
        * @property {String} surname Campo Apellido.
        */
        nc.surname = "";
        /**
        * @property {String} email Campo Email.
        */
        nc.email = "";
        /**
        * @property {String} lang Campo Idioma.
        */
        nc.lang = "";
        /**
        * @property {String} aceptopd Campo LOPD.
        */
        nc.aceptopd = "";
        /**
        * @property {String} Campo Promocional.
        */
        nc.aceptopromo = "";
        /**
        * @property {String} portalCadena Campo cadena.
        */
        nc.portalCadena = sessionStorage["Cadena"];
        nc.status = false;
        nc.sended = false;

        /*        
        * Marca un formulario como enviado.
        * @memberof NewsletterController
        * @function setSubmitted
        */
        nc.setSubmitted = function(form)
        {
            form.$setSubmitted();
            return true;
        }
        /*        
        * Marca un formulario como valido.
        * @memberof NewsletterController
        * @function isValidForm
        */
        nc.isValidForm = function(form)
        {
            form.$submitted;
            return form.$valid;
        }
        /*        
        * Da de alta un usuario dentro de la newsletter.
        * @memberof NewsletterController
        * @function send
        */
        nc.send = function()
        {
            if(nc.sended)
                return ; 

            var params = {
                Pais:nc.country,
                Nombre: nc.name,
                Apellido: nc.surname,
                Email:nc.email,
                Cadena : sessionStorage["Cadena"],
                Lcid : VariableService.getCurrentLanguage(),
                Idioma: VariableService.getCurrentLangCode().toUpperCase(),
            };
            
            nc.sended = true;
            NewsletterService.subscribre(params).then(function(data)
                {
                    //data.RecibirInformacion = (data.RecibirInformacion === "true");
                    //nc.status = data.RecibirInformacion;
                    nc.status = true;
                    $timeout(function(){
                        nc.sended = false;
                    });
                },function(data)
                {
                    $timeout(function(){
                        nc.sended = false;
                    });
                });
        }


    	writeLog("NEWSLETTER LOADED");
    }



})();