(function(){
    'use strict';

    angular
    .module('app')
    .factory('NewsletterService', NewsletterService);

    NewsletterService.$inject = ["$timeout","$http","VariableService"];

    function NewsletterService($timeout,$http,VariableService)
    {
    	var newsletter = {};

        newsletter.subscribre =  function(parms)
        {
            return newsletter.sendMailSuscription(parms);          
        }

        //Funcion RGPD de H10 Hotels
        newsletter.sendMailSuscription = function (parms) {
            //Damos de alta la url de la peticion
            var ajaxUrl = window.ServiceRooms + "/GrpdueSendNewsletterMail";
            var ip = getIP();
            var portalOrigen = sessionStorage.Cadena;
            var language = getCurrentLanguage();
            /*
             * OBjeto utilizado por pasiona
             * "{\n\t\"userMail\" : \"" + userMail + "\",\n\t\"userName\" : \"" + userName + "\",\n\t\"userSurname\" : \"" + userSurname + "\",\n\t\"language\" : \"" + language + "\",\n\t\"portalOrigen\" : \"" + portalOrigen + "\",\n\t\"formularioOrigen\" : \"" + formularioOrigen + "\",\n\t\"ip\" : \"" + ip + "\",\n\t\"userCountry\" : \"" + userCountry + "\"\n}"
             */
            var obj = {
                userMail: parms.Email,
                userName: parms.Nombre,
                userSurname: parms.Apellido,
                language: language,
                portalOrigen: portalOrigen,
                formularioOrigen: "NEWSLETTER",
                userCountry:parms.Pais,
                ip:ip
            };

            return $http.post(ajaxUrl, obj).then(function (data) {
                writeLog
(data);
                return data.data;
            },function(data)
            {
                return data.data;
            });

            //DEPRECATED NO SE EJECUTA CODIGO ORIGINAL PENDIENTE DE BORRAR            
            var validatedForm = true;
            var noAltaExpress = true;
            //Validaciones Jquery

            if (($("#ProtectionData :input").length > 0) && (!$("#ProtectionData :input")[0].checked)) {
                validatedForm = false;
            }

            if (($("#MailSending :input").length > 0) && (!$("#MailSending :input")[0].checked)) {
                validatedForm = false;
            }

            //Fin de las validaciones
            //Extracciones de los datos mas Validacion de formulario.
            var userMail = $(".formularioEmail").val();
            if (userMail == "") validatedForm = false;

            var userName = $(".formularioNombre").val();
            if (userName == "") validatedForm = false;
            var userSurname = $(".formularioApellido").val();
            if (userSurname == "") validatedForm = false;

            var userCountry = "";
            if ($(".formularioPais").length > 0) {
                userCountry = $(".formularioPais option:selected").text();
                if (userCountry == "") validatedForm = false;
            }
            var language = getCurrentLanguage();
            var portalOrigen = sessionStorage.Cadena;
            var formularioOrigen = $("#FormularioTipo").text();
            if (formularioOrigen == "") validatedForm = false;

            var ip = getIP();

            if ($(".textobligatorio:visible").text() != "") {
                validatedForm = false;
            }

            if ($(':radio[value=noSocio]').length > 0) {
                noAltaExpress = $(':radio[value=Socio]').is(':checked');
            }
            //No comprendo por que se hace asi,:S

            if ((validatedForm) && (noAltaExpress)) {
                $(".send-button input").hide();
                $.ajax({
                    type: "POST",
                    contentType: "application/x-www-form-urlencoded",
                    data: "{\n\t\"userMail\" : \"" + userMail + "\",\n\t\"userName\" : \"" + userName + "\",\n\t\"userSurname\" : \"" + userSurname + "\",\n\t\"language\" : \"" + language + "\",\n\t\"portalOrigen\" : \"" + portalOrigen + "\",\n\t\"formularioOrigen\" : \"" + formularioOrigen + "\",\n\t\"ip\" : \"" + ip + "\",\n\t\"userCountry\" : \"" + userCountry + "\"\n}",
                    url: window.ServiceRooms + "/GrpdueSendNewsletterMail",
                    success: function (result) {
                    },
                    error: function (error) {
                    }
                });
            }
            //FIN DEL CODIGO PROVISIONAL 
        }
    	return newsletter ;
    }

})();