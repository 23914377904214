(function() {
    'use strict';
    /**
   * Configura Http para el envio de peticiones a la Rest API.
   * @memberof app
   * @ngdoc service
   * @name config
   * @param {Service} $httpProvider gestor de configuracion de las peticiones .
   */
    angular
        .module('app',['angular.filter']).config(['$httpProvider', function ($httpProvider) { 
       		$httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        	// $httpProvider.defaults.headers.post['X-CSRF-Token'] = jQuery('meta[name="csrf-token"]').attr("content");
        	$httpProvider.interceptors.push(['$q',function ($q) {
             return {
                 'request': function (config) {
                 	 var d = new Date();
                 	 writeLog(config);

                     if(config.method=='GET')
                         config.url = config.url + '?_='+d.getTime();
                     
                     return config || $q.when(config);
              	   }
            	 }
         	}]);

        }]).run(["$rootScope", function ($rootScope) {
            var hadRouteChange = false;

            $rootScope.$on("$routeChangeStart", function () {
                hadRouteChange = true;
            });
            // The following listener is required if you're using ui-router
            $rootScope.$on("$stateChangeStart", function () {
                hadRouteChange = true;
            });

            function hookAngularBoomerang() {
                if (window.BOOMR && BOOMR.version) {
                    if (BOOMR.plugins && BOOMR.plugins.Angular) {
                        BOOMR.plugins.Angular.hook($rootScope, hadRouteChange);
                    }
                    return true;
                }
            }

            if (!hookAngularBoomerang()) {
                if (document.addEventListener) {
                    document.addEventListener("onBoomerangLoaded", hookAngularBoomerang);
                } else if (document.attachEvent) {
                    document.attachEvent("onpropertychange", function (e) {
                        e = e || window.event;
                        if (e && e.propertyName === "onBoomerangLoaded") {
                            hookAngularBoomerang();
                        }
                    });
                }
            }
        }]);

})();
