(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc filter
    * @name ucwords
    * @description
    * Filtro que pone el primer caracter de un string en mayusculas.
    */

    angular.module('app').filter("phone", function () {
    	return function (input){
        	if(input) { 
           		input = input.toLowerCase().replace("(","").replace(")","").replace("T","").replace(" ","");
        	}
        	return input; 
    	}    
	});

})();