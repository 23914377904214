(function () {
    'use strict';

    /**
    * @memberof app
    * @ngdoc directive
    * @name lazy
    * @param {service} $timeout settimeout de angular.
    * @param {service} $parse Compilador de AngularJS.
    * @description
    * Cargado de las fotos a partir de Lazy load.
    */

    angular
    .module('app').directive('lazy', lazy);

    lazy.$inject = ['$timeout', '$parse'];

    function lazy($timeout, $parse) {
        return {
            restrict: 'A',
            link: function (scope, element, $attrs) {
                return $timeout(function () {
                    var value = $attrs.value;
                    var $element = $(element);
                    $element.Lazy({
                        scrollDirection: 'vertical',
                        effect: 'fadeIn',
                        visibleOnly: true,
                        effectTime: '200'
                    });
                    


                });
            }
        };
    }


})();