(function(){
    'use strict';

    /**
     * @memberof app
     * @ngdoc controller
     * @name AppController
     * @param {service} $timeout Settimeout de angular.
     * @param {service} PhonesService Obtiene y gestiona los Telefonos desde la REST Api.
     * @param {service} VariableService Gestiona las Variables del sistema.
     * @param {service} UserService Obtiene y gestiona el Usuario desde la REST Api.
     * @param {service} BookingService Gestiona el sistema de reservas.
     * @param {service} DateService Gestiona las fechas.
     * @description
     * Controlador principal del aplicativo (Se utiliza en todos los Webparts si es necesario y tambien dentro de todos los ControlTemplates ).
     */

    angular
    .module('app')
    .controller('AppController', AppController);

    AppController.$inject = ["$timeout", "PhonesService", "VariableService", "UserService", "BookingService","DateService"];

    function AppController($timeout, PhonesService, VariableService, UserService, BookingService,DateService)
    {

        var vm = this;
        /**
        * @property {Array} phoneList Coleccion de telefonos.
        */
        vm.phoneList = [];
        /**
        * @property {Object} selectedPhone Telefono seleccionado.
        */
        vm.selectedPhone;
        

        vm.user = UserService;
        vm.booking = BookingService;

        /**
        * Selecciona un teléfono
        * @memberof AppController
        * @function setPhone
        * @param {Object} s Objeto de un telefono
        */
        vm.setPhone = function(p)
        {
            vm.selectedPhone = p;
        }
        /**
        * Recupera todos los telefonos.
        * @memberof AppController
        * @function getPhones
        */
        PhonesService.getPhones().then(function(data)
        {
            vm.selectedPhone = data[0];
            vm.phoneList = data;
        });

        /**
        * Devuelve la url de un idioma para una pagina
        * @memberof AppController
        * @function getLanguageUrl
        * @param {String} lang Idioma en fromato [es].
        */
        vm.getLanguageUrl = function(lang)
        {
            if(!VariableService.languagesLinks.length)
                return '/'+lang;
            for (var i = 0; i < VariableService.languagesLinks.length; i++) {
                 if(VariableService.languagesLinks[i].Id.toLowerCase() == lang.toLowerCase())
                    return VariableService.languagesLinks[i].Url;
            } 
            return '/'+lang.toLowerCase();
        }

        /**
        * Transforma un numero en un array secuencial.
        * @memberof AppController
        * @function serializeNumber
        * @param {Integer} number Numero de elementos a transformar
        */
    	vm.serializeNumber = function(number)
    	{
            var arr = [];
            for (var i = 0; i < number; i++) {
                arr.push(i);
            }
    		return arr;
    	};
        /**
        * Cambia el idioma.
        * @memberof AppController
        * @function ChangeVariationLanguage
        * @param {String} lang Idioma en fromato [es].
        */
        vm.ChangeVariationLanguage = function(lang)
        {
            var url = vm.getLanguageUrl(lang);

            VariableService.ChangeVariationLanguage(lang,url);
        }
       
        
        if(!VariableService.isEditMode()){
            writeLog("disabling form");
            $("#"+MSOWebPartPageFormName).on("submit",function(evt){
                //evt.preventDefault();
                return false;
            });
        }
            


    }

})();