 (function(){
    'use strict';
     /**
    * @memberof app
    * @ngdoc directive
    * @name daterangepicker
    * @param {service} $timeout settimeout de angular.
    * @param {service} $parse Compilador de AngularJS.
    * @param {service} BookingService Gestiona el sistema de reservas.
    * @param {service} VariableService Gestiona las Variables del sistema.
    * @param {service} DateService Gestiona las fechas.   
    * @description
    * Directiva que controla el input de las fechas.
    */
    angular
    .module('app').directive('daterangepicker', daterangepicker);

    daterangepicker.$inject = ['$timeout', '$parse','BookingService','VariableService','DateService'];

    function daterangepicker($timeout, $parse,BookingService,VariableService,DateService){
        return {
            restrict: 'A',
            require: 'ngModel',
            
            link: function (scope, element, $attrs, ngModel,rootScope) {
                return $timeout(function () {
                    BookingService.initDates();
                    var value = $attrs.value;
                    var $element = $(element);
                    var today = moment();
                    
                    /**
                     * Hay que valorar si las fechas existen o son correctas para evitar valores null.
                     */

    				var configDates = {
    				    autoClose: true,
    				    format: 'DD/MM/YYYY',
    				    separator: ' - ',
    				    language: VariableService.getCurrentLangCode(),
    				    startOfWeek: 'monday',// or monday
    				    startDate: today,
    				    stickyMonths: true,
                        minDays:2,
                        maxDays:45,
    				    //endDate: endDate,
    				    setValue: function(s)
						{
							if(!$(this).attr('readonly') && !$(this).is(':disabled') && s != $(this).val())
							{
							    var dStart = s.split('-')[0].trim();
							    var dEnd = s.split('-')[1].trim();
							    
								writeLog(DateService.diff(dStart,dEnd));
								$(this).val(s);
								scope.$apply(function () {
                                	ngModel.$setViewValue($element.val());
                            	});
							}
						},
    				};

    				/*$element.dateRangePicker(configDates).bind('datepicker-first-date-selected', function (event, obj) {
    				    console.log(obj);
    				});*/
    				var initialDate = null;
    				var finalDate = null;
    				$element.dateRangePicker(configDates).bind('datepicker-change',function(event,obj){
    				    $('#header').removeClass('hidden');
    				    $(this).closest('.popxs').removeClass('open');
    				}).bind('datepicker-open', function () {
    				    /* This event will be triggered before date range picker open animation */
    				    console.log('before open');
    				    initialDate = null;
    				}).bind('datepicker-first-date-selected', function (event, obj) {
    				    console.log(obj);
    				    initialDate = obj.date1;
    				    var s = $(this).val();
    				    finalDate = s.split('-')[1].trim();
    				    //$(this).data('dateRangePicker').setEnd(null);
    				}).bind('datepicker-closed', function () {
    				    if (initialDate) {
    				        var s = $(this).val();
    				        console.log(s);
    				        var dStart = s.split('-')[0].trim();
    				        var dEnd = s.split('-')[1].trim();
    				        console.log(moment(initialDate).format("DD/MM/YYYY"));
    				        console.log(dEnd);
    				        if (moment(initialDate).format("DD/MM/YYYY") != dStart && finalDate == dEnd) {
    				            console.log(moment(initialDate).add('days', 1 * window.DefaultNights).format("DD/MM/YYYY"));
    				            $(this).data('dateRangePicker').setEnd(moment(initialDate).add('days', 1 * window.DefaultNights).format("DD/MM/YYYY"));
    				        }
    				        initialDate = null;
    				    }
    				});

    			
					/**
					*	COSAS NECESARIAS DE ESTILO
					*/
    				$('#btnDates').click(function(evt){
    				    evt.stopPropagation();
                        $('#collapseRooms').collapse("hide");
    				    if ($(window).width() + scrollCompensate() < 768) {
    				        $('#header').addClass('hidden');
    				        $(this).closest('.popxs').addClass('open');
    				        setTimeout(function(){
    				            $element.data('dateRangePicker').open();
    				        }, 400);
    				    } else {
    				        if (!$('#reservas').hasClass('fixed')){
                                $('#reservas').addClass('fixed', function(){
                                    setTimeout(function(){
                                        $element.data('dateRangePicker').open();
                                    }, 600);
                                });
                                $('#header').addClass('noshadow');
    				        } else{
    				            $element.data('dateRangePicker').open();
    				        }
    				    }
    				});

    				$('#btnDatesClose').click(function(evt){
    				    evt.stopPropagation();
    				    $element.data('dateRangePicker').close();
    				    $('#header').removeClass('hidden');
    				    $(this).closest('.popxs').removeClass('open');
    				});

                    // Close daterange on scroll
    				$(window).on('scroll', function () {
    				    $element.data('dateRangePicker').close();
    				    $('#header').removeClass('hidden');
    				    $(this).closest('.popxs').removeClass('open');
    				});
    				/**	
    				*	FIN DE COSAS NECESARIAS DE ESTILO
   					*/
                });
            }
        };
    }


})();