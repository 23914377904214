(function(){
    'use strict';

    angular
    .module('app')
    .factory('FooterDestinations', FooterDestinations);

    FooterDestinations.$inject = ["$timeout","$http","VariableService"];

    function FooterDestinations($timeout,$http,VariableService)
    {

    	var links = {};

        //http://sp2013:32940/_vti_bin/H10_WebServices/H10_Login.svc/GetDestinosFooter/3082?_=1503323258354
        /**
        * GetDestinosFooter
        */
    	links.getDestinosFooter = function()
        {
            var currentLanguage = VariableService.language;
            var url  = ServiceUrl+"/GetDestinosFooter/"+currentLanguage

            var  successCallback = function(data)
            {
                return data.data;
            }

            var errorCallback = function(data)
            {
                return data.data;
            }

            return $http.get(url).then(successCallback, errorCallback);
        }

    	return links;
    }

})();