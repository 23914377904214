(function(){
    'use strict';

    angular
    .module('app')
    .factory('UserService', UserService);

    UserService.$inject = ["$timeout","$http","CookieService","VariableService"];

    function UserService($timeout,$http,CookieService,VariableService)
    {

        //creación de variables 
    	var u = {};
        u.loginError = false;
        u.loginErrorMessage = "";
        u.getError = function(form)
        {
            if(form){
                if(form.$submitted)
                {
                    if(u.loginError)
                        return true;
                }
            }
            return false;
        }

        u.data = {
            "NombreCompleto":"",
            "Email":"",
            "Nombre":"",
            "Name":"",
            "LastName":"",
            "LastName2":"",
            "StateClubH10":"",
            "Country":"",
            "Phone":"",
            "CodigoClienteClub":"",
            "CodigoClienteFacebook":"",
            "oauth_tokenTwitter":"",
            "oauth_token_secretTwitter":""
        };

        u.agencies = {
            "CompanyName":"",
            "CompanyCode":"",
            "NombreCompleto":"",
        };

        u.estancias = null;
        //Esto no es necesario si se refresca la pagina.
        u.logout = function()
        {
            u.data = null;
            u.estancias = null;
        }
        //Dice si un usuario se ha logueado
        u.islogged = function()
        {
            if (CookieService.readCookie(window.cookiecodigoclienteclub) == null) {
                return true;
            }
            return false;
        }
        //Dice si una Agencia se ha logueado
        u.isloggedAgencia = function()
        {
            if (CookieService.readCookie(window.agencianame) == null) {
                return true;
            }
            return false;
        }
        //Setea datos de un usuario
        u.setData = function()
        {
            u.data.NombreCompleto = CookieService.readCookie(window.cookieuserdisplayname);
          //  u.data.Email = CookieService.readCookie(window.cookieemail);
            u.data.Nombre = CookieService.readCookie(window.userdisplayname);
            u.data.Name = CookieService.readCookie(window.cookieusername);
            u.data.LastName = CookieService.readCookie(window.cookieuserlastname);
            u.data.LastName2 = CookieService.readCookie(window.cookieuserlastname2);
            u.data.StateClubH10 = CookieService.readCookie(window.cookieuserh10state);
            u.data.Country = CookieService.readCookie(window.cookieusercountry);
            u.data.Email = CookieService.readCookie(window.cookieemail);
            u.data.Phone = CookieService.readCookie(window.cookiephone);
            u.data.Phone = CookieService.readCookie(window.cookieuserpostalcode);
            u.data.CodigoClienteClub = CookieService.readCookie(window.cookiecodigoclienteclub);
            u.data.CodigoClienteFacebook = CookieService.readCookie(window.cookiecodigoclientefacebook);
            u.data.oauth_tokenTwitter = CookieService.readCookie(window.cookieoauth_tokenTwitter);
            u.data.oauth_token_secretTwitter = CookieService.readCookie(window.cookieoauth_token_secretTwitter);
        }
        //setea datos de una agencia 
        u.setDataAgencias = function()
        {
            u.agencies.CompanyName =CookieService.readCookie(window.agencianame);
            u.agencies.CompanyCode =CookieService.readCookie(window.agenciacode);
            u.agencies.NombreCompleto =CookieService.readCookie(window.agenciadisplayname);
        }
        //Inicializa los datos de una agencia
        u.H10LogedAgencia = function()
        {
            var cookieData = "";
            if (CookieService.readCookie(window.agencianame) == null) {
                return true;
            } else {
                cookieData = CookieService.readCookie(window.agencianame);
            }
            if (cookieData != "") {
                u.setAgencia(cookieData);
            }
            return true;
        }
        //Iniciacializa los datos de un usuario
        u.H10LogedUser = function() {
            var cookieData = "";
            if (CookieService.readCookie(window.cookiecodigoclienteclub) == null) {
                return true;
            } else {
                cookieData = CookieService.readCookie(window.cookieuserdisplayname);
            }
            if (cookieData != "") {
                u.setUsername(cookieData);
            }
            return true;
        }
        //Setea todas las dependencias de una Agencia 
        u.setAgencia = function()
        {
            u.setDataAgencias();
        }

    	//Setea todas las dependencias de un usuario 
		u.setUsername = function(data) {
            u.setData();
    		u.setMisEstancias();
            writeLog
(u.data);
    		//setTimeout(setMisEstancias, 200);
		}
        //Setea las estancias de un usuario 
        u.setMisEstancias = function() {
                try {
                    var ajaxUrl = ServiceRooms + "/GetMisEstanciasCount/" + VariableService.getCurrentLanguage() + "/" + stringToAscii(CookieService.readCookie(window.cookiecodigoclienteclub));
                    $http.get(ajaxUrl).then(function(data){
                        writeLog
(data);
                        u.estancias = data.data;
                    });
                } catch (err) {
                    writeLog("setMisEstancias ERROR :" + err);
                }
        }

    	return u ;
    }

})();
