 (function(){
    'use strict';

    angular
    .module('app').directive('easyAutocomplete', easyAutocomplete);

    easyAutocomplete.$inject = ['$timeout', '$parse','$rootScope',"BookingService"];

    function easyAutocomplete($timeout, $parse,$rootScope,BookingService){
        return {
            restrict: 'A',
            require: 'ngModel',
            
            link: function (scope, element, $attrs, ngModel,rootScope) {
                return $timeout(function () {
                    var value = $attrs.value;
                    var $element = $(element);
                     // Automplete reservas
    				var optDestinations = {
    				    data:{
    				        "destinos": [
    				           
    				        ],
    				        "hoteles": [
    				           
    				        ]
    				    },               
				
    				    //url: "js/json/destinos-hoteles.json",
    				    categories: [
    				        {   //Category fruits
    				            listLocation: "destinos",
    				            header: "Destinos"
    				        }, 
    				        {   //Category vegetables
    				            listLocation: "hoteles",
    				            header: "Hoteles"
    				        }
    				    ],
    				    getValue: function(element) {
    				        return element.name;
    				    },
    				    template: {
    				        type: "custom",
    				        method: function(value, item) {
    				            return "<span class='icon icon-" + item.icon + "'></span> " + value;
    				        }
    				    },
    				    list: {
                            showAnimation: {
                                type: "slide", //normal|slide|fade
                                time: 400,
                                callback: function() {}
                            },
                            hideAnimation: {
                                type: "slide", //normal|slide|fade
                                time: 400,
                                callback: function() {}
                            },
    				        sort: {
    				            enabled: true
    				        },
    				        match: {
    				            enabled: true
    				        },
                            onShowListEvent: function() {
                                $('#collapseRooms').collapse("hide");
                                //$('#destination-menu').removeClass('open');
                            }
    				    }
    				};
    				for (var i = 0; i < BookingService.hotelsList.length; i++) {

                            optDestinations.data.destinos.push( {"name":BookingService.hotelsList[i].Description,"icon":"location"} );

                            for (var j = 0; j < BookingService.hotelsList[i].Hotels.length; j++) {

                                optDestinations.data.hoteles.push( {"name":BookingService.hotelsList[i].Hotels[j].HotelName,"icon":"hotel"});
                            }
                        }
                        
    				$element.easyAutocomplete(optDestinations);

    				if ($(window).width() + scrollCompensate() < 740) {
    				    $element.on("focus", function () {
    				        $('#header').addClass('hidden');
    				        $(this).closest('.popxs').addClass('open');
    				    });
    				    $element.keypress(function (e) {
    				        var key = e.which;
    				        if(key == 13){
    				            $('#header').removeClass('hidden');
    				            $(this).closest('.popxs').removeClass('open');
    				            return false;
    				        } else {
    				            if(!$(this).closest('.popxs').hasClass('open')){
    				                $('#header').addClass('hidden');
    				                $(this).closest('.popxs').addClass('open');
    				            }
    				        }
    				    });
    				    
    				    $('#btnDatesClose').click(function(evt){
    				        evt.stopPropagation();
    				    });
    				} else {
    				    $(document).on('click', '#reservas', function () {
    				        if (!$('#reservas').hasClass('fixed')){
                                $('#reservas').addClass('fixed');
                                $('#header').addClass('noshadow');
    				        }
    				    }).on('click', '#reservas .btn', function () {
                            if (!$('#reservas').hasClass('fixed')){
                                $('#reservas').addClass('fixed');
                                $('#header').addClass('noshadow');
                            }
                        }).on('focus', '#reservas input', function () {
                            if (!$('#reservas').hasClass('fixed')){
                                $('#reservas').addClass('fixed');
                                $('#header').addClass('noshadow');
                            }
                        });
    				}

                    $('#btnCloseDest').click(function(evt){
                        evt.stopPropagation();
                        $('#header').removeClass('hidden');
                        $(this).closest('.popxs').removeClass('open');
                        $('#destinationMenu').removeClass('open');
                    });
                    $('#btnMenuDest').click(function(evt){
                        evt.stopPropagation();
                        $('#header').removeClass('hidden');
                        $(this).closest('.popxs').removeClass('open');
                        $('#destinationMenu').removeClass('open');
                    });

                    $('#r_destination').focusin(function(){
                        //$('#collapseRooms').collapse("hide");
                        //$(this).closest('.popxs').addClass('open');
                        var destText = $('#r_destination').val();
                        if (!destText && !$('#destinationMenu').hasClass('open')){
                            //$('#destination-menu').addClass('open');
                        }
                    });
                    $("#r_destination").keypress(function() {
                        var destText = $('#r_destination').val();
                        if (!destText && !$('#destinationMenu').hasClass('open')){
                            //$('#destination-menu').addClass('open');
                        }
                    });
                    $('#r_destination').focusout(function(){
                        ////$('#destination-menu').removeClass('open');
                    });


                });
            }
        };
    }


})();