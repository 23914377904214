(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc service
    * @name CookieService
    * @param {service} $parse angular html parser
    * @param {service} $timeout angular window.setTimeout service
    * @description 
    *   Gestion de las Cookies.
    */
    angular
    .module('app')
    .factory('CookieService', CookieService);

    CookieService.$inject = ["$timeout"];
    /**
     * Metodos para el servicio de Cookies
     */
    function CookieService($timeout)
    {
    	var cookie = {};

    	cookie.days = 1;
    	/**
    	* Aumenta el número de dias de una cookie
    	* @memberof CookieService
        * @param {Integer} d numero de dias
    	*/
    	cookie.setDays = function(d)
    	{
    		cookie.days = d;
    	}
    	/**
    	* Reset de los dias de una cookie
        * @memberof CookieService
        * @param {Integer} d numero de dias
    	*/
    	cookie.resetDays = function(d)
    	{
    		cookie.days = 1;
    	}


        /**
         * Crea una cookie.
        * @memberof CookieService
        * @param {String} name Nombre de la cookie
        * @param {String} value Valor de la cookie
        * @param {String} expires Tiempo de expiracion
        * @param {String} path Ruta de la cookie
        * @param {String} domain Dominio de la cookie
    	*/
		cookie.createCookie = function(name, value, expires, path, domain) {
		
		    if (expires) {
		        // If it's a date
		        if (expires instanceof Date) {
		            // If it isn't a valid date
		            if (isNaN(expires.getTime()))
		                expires = new Date();
		        }
		        else
		            expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24 * cookie.days);
		
		    }
		    else {
		        expires = new Date(new Date().getTime() + 1 * 1000 * 60 * 60 * 24 * cookie.days);
		
		    }
		    $.cookie(name, value, { expires: expires, path: '/', domain: window.cookiesDomain });
		}
		
		/**
		 * Borra una cookie
		 * @memberof CookieService
         * @param {String} name Nombre de la cookie
         * @param {String} path Ruta de la cookie
         * @param {String} domain Dominio de la cookie
		 */
		cookie.deleteCookie = function(name, path, domain) {
		    $.removeCookie(name, { path: '/', domain: window.cookiesDomain });
		}
		/**
		 * Devuelve el valor de una cookie
		 * @memberof CookieService
         * @param {String} name Nombre de la cookie
		 */
		cookie.readCookie = function(name) {
		    return $.cookie(name);
		}
		/**
		 * Borra todas las cookies
		 * @memberof CookieService
		 */
		 
		cookie.deleteAllCookies = function() {
		    var cookies = document.cookie.split(";");
		
		    for (var i = 0; i < cookies.length; i++) {
		        var cookie = cookies[i];
		        var eqPos = cookie.indexOf("=");
		        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		    }
		}
		

		/**
		 * Valida si existe una cookie
  	     * @memberof CookieService
		 */
		cookie.checkCookie  = function() {
		
		    if (cookie.readCookie("visitedweb") == null) {
		        cookie.createCookie("visitedweb", "true", null, "/", document.domain);
		    } else {
		        jQuery(".cc-cookies").hide();
		    }
		
		}
    	
        /**
         * Devuelkve el objeto cookie.
         */
    	return cookie;
   
    }

})();