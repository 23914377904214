 (function(){
    'use strict';
     /**
    * @memberof app
    * @ngdoc directive
    * @name destination
    * @param {service} $timeout settimeout de angular.
    * @param {service} $parse Compilador de AngularJS.
    * @param {service} BookingService Gestiona el sistema de reservas.
    * @description
    * Autocompletado de Hoteles y destinaciones.
    */
    angular
    .module('app').directive('destination', easyAutocomplete);

    easyAutocomplete.$inject = ['$timeout', '$parse','$rootScope',"BookingService"];

    function easyAutocomplete($timeout, $parse,$rootScope,BookingService){
        return {
            restrict: 'A',
            require: 'ngModel',
            
            link: function (scope, element, $attrs, ngModel,rootScope) {
                return $timeout(function () {
                    var value = $attrs.value;
                    var $element = $(element);
                   

                   
                    // Automplete reservas
                    var optDestinations = {
                        data:{
                            "destinos": [
                               
                            ],
                            "hoteles": [
                               
                            ],
                            "etiquetas":[

                            ]
                        },               
                        //url: "js/json/destinos-hoteles.json",
                        categories: [
                            {   //Category fruits
                                maxNumberOfElements: 100,
                                listLocation: "destinos",
                                header: "Destinos"
                            }, 
                            {   //Category vegetables
                                maxNumberOfElements: 100,
                                listLocation: "hoteles",
                                header: "Hoteles"
                            },
                            {   //Category vegetables
                                maxNumberOfElements: 100,
                                listLocation: "etiquetas",
                                header: "Etiquetas"
                            }
                        ],
                        getValue: function(element) {
                            return element.name;
                        },
                        template: {
                            type: "custom",
                            method: function(value, item) {
                                return '<span class="icon icon-' + item.icon + '" ></span> ' + value;
                            }
                        },
                        list: {
                            maxNumberOfElements: 100,
                            showAnimation: {
                                type: "slide", //normal|slide|fade
                                time: 400,
                                callback: function() {}
                            },
                            hideAnimation: {
                                type: "slide", //normal|slide|fade
                                time: 400,
                                callback: function() {}
                            },
                            sort: {
                                enabled: true
                            },
                            match: {
                                enabled: true,
                                method: function (element, phrase) {
                                    console.log(phrase.latinize());
                                    console.log(element);
                                    if (element.latinize().search(phrase.latinize()) > -1) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            },
                            onShowListEvent: function () {
                                $('#collapseRooms').collapse("hide");
                                $('.destination .popxs').addClass("open");
                                //$('#destination-menu').removeClass('open');
                            },
                            onSelectItemEvent: function ()
                            {
                                var searchoptDestinations = function(v)
                                {
                                    var arr = ['destinos','hoteles','etiquetas'];
                                    for (var i = 0; i < arr.length; i++) {
                                        for (var j = 0; j < optDestinations.data[arr[i]].length; j++) {
                                            if(v == optDestinations.data[arr[i]][j].name ){
                                                writeLog(optDestinations.data[arr[i]][j].name);
                                                return arr[i];
                                            }
                                        }
                                        
                                    }
                                    return false;
                                }

                                var searchDestinationByTag = function(v)
                                {
                                    var arr = ['destinos','hoteles','etiquetas'];
                                    for (var i = 0; i < arr.length; i++) {
                                        for (var j = 0; j < optDestinations.data[arr[i]].length; j++) {
                                            if(v == optDestinations.data[arr[i]][j].name ){
                                                writeLog(optDestinations.data[arr[i]][j].name);
                                                return optDestinations.data[arr[i]][j];
                                            }
                                        }
                                    }
                                    return false;

                                }

                                var option = searchoptDestinations($element.val()); 
                                writeLog($element.val());
                                writeLog( option );

                                if(option == "destinos" )
                                {
                                    $timeout(function(){
                                        BookingService.resetHotel();
                                        BookingService.setDestination( BookingService.indexOfDestination($element.val()) );
                                    });
                                    
                                }
                                else if(option == "hoteles")
                                {
                                    var desti = "";
                                    for (var i = 0; i <  optDestinations.data.hoteles.length; i++) {
                                         if( optDestinations.data.hoteles[i].name==$element.val() )
                                         {
                                            desti = optDestinations.data.hoteles[i].destination;
                                         }
                                    }

                                    var n = $element.val().lastIndexOf(desti);

                                    // slice the string in 2, one from the start to the lastIndexOf
                                    // and then replace the word in the rest
                                    var str = $element.val().slice(0, n).trim();
                                    $element.val(str );
                                    writeLog(desti);
                                    writeLog($element.val());
                                    writeLog( BookingService.indexOfHotel( desti,$element.val() ) );
                                    $timeout(function(){
                                        BookingService.setDestination( BookingService.indexOfDestination(desti) );
                                        BookingService.setHotel( BookingService.indexOfHotel(desti, $element.val() ) );
                                    });
                                }
                                else if(option == "etiquetas")
                                {
                                    var desti = searchDestinationByTag($element.val()).destination;
                                    writeLog(desti);
                                    $timeout(function(){
                                        BookingService.resetHotel();
                                        BookingService.setDestination( BookingService.indexOfDestination(desti) );
                                    });
                                }
                                else
                                {

                                }

                            }
                        }
                    };

                    for (var i = 0; i < BookingService.hotelsList.length; i++) {
                        optDestinations.data.destinos.push( {"name":BookingService.hotelsList[i].Description,"icon":"location"} );

                        for (var j = 0; j < BookingService.hotelsList[i].Hotels.length; j++) {

                            optDestinations.data.hoteles.push( {"name":BookingService.hotelsList[i].Hotels[j].HotelName + " " + BookingService.hotelsList[i].Description,"destination":BookingService.hotelsList[i].Description ,"icon":"location"});
                        }

                        if (BookingService.hotelsList[i].LugaresDeInteres)
                            writeLog(BookingService.hotelsList[i].LugaresDeInteres);

                        if (BookingService.hotelsList[i].LugaresDeInteres)
                        {
                            for (var h = 0; h < BookingService.hotelsList[i].LugaresDeInteres.split(',').length; h++) {
                                optDestinations.data.etiquetas.push({ "name": BookingService.hotelsList[i].LugaresDeInteres.split(',')[h] + " " + BookingService.hotelsList[i].Description, "destination": BookingService.hotelsList[i].Description, "icon": "location" });
                            }
                        }
                    }
                        
                    $element.easyAutocomplete(optDestinations);
                    //
    				
    				$element.on("focus", function () {
    				    if ($(window).width() + scrollCompensate() < 768) {
    				        $('#header').addClass('hidden');
    				        $(this).closest('.popxs').addClass('open');
    				    }
    				});
    				$element.keypress(function (e) {
    				    if ($(window).width() + scrollCompensate() < 768) {
    				        var key = e.which;
    				        if (key == 13) {
    				            $('#header').removeClass('hidden');
    				            $(this).closest('.popxs').removeClass('open');
    				            return false;
    				        } else {
    				            if (!$(this).closest('.popxs').hasClass('open')) {
    				                $('#header').addClass('hidden');
    				                $(this).closest('.popxs').addClass('open');
    				            }
    				        }
    				    }
    				});
    				$(document).on('click','#eac-container-r_destination ul li',function () {
    				    if ($(window).width() + scrollCompensate() < 768) {
    				        $('#header').removeClass('hidden');
    				        $(this).closest('.popxs').removeClass('open');
    				    }
    				});
    				$('#btnDatesClose').click(function(evt){
    				    if ($(window).width() + scrollCompensate() < 768) {
    				        evt.stopPropagation();
    				    }
    				});
    				
                    $(document).on('click', '#reservas', function () {
                        if ($(window).width() + scrollCompensate() >= 768) {
                            if (!$('#reservas').hasClass('fixed')){
                                $('#reservas').addClass('fixed');
                                $('#header').addClass('noshadow');
                            }
                        }
                    }).on('click', '#reservas .btn', function () {
                        if ($(window).width() + scrollCompensate() >= 768) {
                            if (!$('#reservas').hasClass('fixed')) {
                                $('#reservas').addClass('fixed');
                                $('#header').addClass('noshadow');
                            }
                        }
                    }).on('focus', '#reservas input', function () {
                        if ($(window).width() + scrollCompensate() >= 768) {
                            if (!$('#reservas').hasClass('fixed')){
                                $('#reservas').addClass('fixed');
                                $('#header').addClass('noshadow');
                            }
                        }
                    });
    				

                    $('#btnCloseDest').click(function(evt){
                        evt.stopPropagation();
                        $('#header').removeClass('hidden');
                        $(this).closest('.popxs').removeClass('open');
                        $('#destinationMenu').removeClass('open');
                    });
                    $('#btnMenuDest').click(function(evt){
                        evt.stopPropagation();
                        $('#header').removeClass('hidden');
                        $(this).closest('.popxs').removeClass('open');
                        $('#destinationMenu').removeClass('open');
                    });

                    $('#r_destination').focusin(function(){ 
                        $('#collapseRooms').collapse("hide");
                        //$(this).closest('.popxs').addClass('open');
                        var destText = $('#r_destination').val();
                        if (!destText && !$('#destinationMenu').hasClass('open')){
                            //$('#destination-menu').addClass('open');
                        }
                    });
                    $("#r_destination").keypress(function() {
                        var destText = $('#r_destination').val();
                        if (!destText && !$('#destinationMenu').hasClass('open')){
                            //$('#destination-menu').addClass('open');
                        }
                    });
                    $('#r_destination').focusout(function(){
                        ////$('#destination-menu').removeClass('open');
                    });


                });
            }
        };
    }


})();