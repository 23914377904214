(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc directive
    * @name destinationValidation
    * @param {service} $q Implementacion de promesas para angularJS.
    * @param {service} $timeout settimeout de angular.
    * @param {service} $parse Compilador de AngularJS.
    * @param {service} BookingService Gestiona el sistema de reservas.
    * @description
    * Valida el campo Destinaciones tanto Destinos como hoteles.
    */

    angular
        .module('app').directive('destinationValidation',destinationValidation);

    destinationValidation.$inject = ['$q','$timeout', '$parse','$rootScope',"BookingService"];


function destinationValidation($q, $timeout,$parse,$rootScope, BookingService) {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$asyncValidators.destinationValidation = function(modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty model valid
         // return $q.resolve();
        }

        var def = $q.defer();

        $timeout(function() {
          // Mock a delayed response
          if (BookingService.getHotelOrDestination(modelValue)) {
            // The username is available
            def.resolve();
          } else {
            def.reject();
          }
        });
        return def.promise;

      };
    }
  };
};

})();