(function(){
    'use strict';

    angular
    .module('app')
    .factory('InstagramService', InstagramService);

    InstagramService.$inject = ["$timeout","$http","VariableService","BookingService"];

    function InstagramService($timeout,$http,VariableService,BookingService)
    {

    	var instagram = {};

        //https://www.socialmediawall.io/api/v1.1/14231/posts/?app_id=a2a4e3a8b2194b76bb2bc8abc42ddf50&app_secret=eeefeb0a0d534d6bb3883c1ee949bd1e&limit=8&offset=0
       instagram.getPost = function()
       {
          var currentLanguage = VariableService.language;
          var url  = window.ServiceMasterUrl+"/GetWall/"+currentLanguage+"/"+sessionStorage["Cadena"]

       		//var url = "http://sp2013:32940/_vti_bin/H10_WebServices/H10_Home.svc/GetWall/3082/H10";
       		return $http.get(url).then(function(data){
       		    return data.data.data;
       		});
       }

       instagram.getLink = function(h)
       {
        for (var i = 0; i < BookingService.hotelsList.length; i++) {
              for (var j = 0; j < BookingService.hotelsList[i].Hotels.length; j++) {
                if(BookingService.hotelsList[i].Hotels[j].CodAbreviado === h  || BookingService.hotelsList[i].Hotels[j].HotelName===h)
                  return BookingService.hotelsList[i].Hotels[j].UrlHotel;
             }
          }
          return "#";
       }


       instagram.hotelname = function(h)
       {
        for (var i = 0; i < BookingService.hotelsList.length; i++) {
              for (var j = 0; j < BookingService.hotelsList[i].Hotels.length; j++) {
                if(BookingService.hotelsList[i].Hotels[j].CodAbreviado === h  || BookingService.hotelsList[i].Hotels[j].HotelName===h)
                  return BookingService.hotelsList[i].Hotels[j].HotelName;
             }
          }
          return "";
       }
    	

    	return instagram;
    }

})();