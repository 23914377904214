(function(){
    'use strict';

    angular
    .module('app')
    .factory('BookingService', BookingService);

    BookingService.$inject = ["$timeout","VariableService","$http","DateService","StorageService","$q","$filter"];

    function BookingService($timeout,VariableService,$http,DateService,StorageService,$q,$filter)
    {
      //Hay que utilizar H10Ocupation es una clase de H10Core.js

	 	  var geocoder = null;

    	var bk = {
    		destination:"",
        destinationIndex:-1,
        destinationType:"Destinations",
        hotel: -1,
        ageUp: false,
    		dates:"",
        dStart:"",
        dEnd:"",
    		discount:"",
    		extras:{
    			numberRooms:1,
    			rooms:[]
    		},
        hotelsList:[]
    	};

    	var room = function(){
    			this.adults = 2;
    			this.kids = 0;
    			this.years = [];
          this.numRoom = 1;
    	};
    	
      bk.extras.rooms.push(new room());

      /**
      * Se inicializan las fechas.
      */

      bk.initDates =  function()
      {
        var startDate = moment(); // La data d'avui
        var endDate = moment().add('days', 1 * window.DefaultNights);
                            
        if (localStorage.getObject("DBegin") && localStorage.getObject("DBegin") != "") { //Si hi ha data d'entrada a LS anterior la fem servir
            
            var savedBeginDate = moment(); //Guardem la data que hi ha a localstorage DBegin
            savedBeginDate = localStorage.getObject("DBegin");
            
            if (savedBeginDate < startDate) { //Comparem les dues dates si la data guardada es de pasat no es fa servir
                startDate = startDate;
                StorageService.setObject("DBegin", DateService.toDate(startDate));
            }
            else {
                startDate = localStorage.getObject("DBegin");
            }            
        }
        else
        {  
            StorageService.setObject("DBegin", DateService.toDate(startDate));  //Si no hi ha data d'entrada a LS setejem l'actual
        }          

        if (localStorage.getObject("DEnd") && localStorage.getObject("DEnd") != "") { //Si hi ha data de sortida a LS anterior la fem servir
             
            var savedEndDate = moment(); //Guardem la data que hi ha a localstorage DEnd
            savedEndDate = localStorage.getObject("DEnd");

            if (savedEndDate < moment()) { //Comparem les dues dates si la data guardada es de pasat no es fa servir
                endDate = endDate;
                StorageService.setObject("DEnd", DateService.toDate(endDate));
            }
            else{
                endDate = localStorage.getObject("DEnd");
            }           
        }
        else
        {   
            StorageService.setObject("DEnd", DateService.toDate(endDate)); //Si no hi ha data de sortida a LS setejem l'actual
        }          

        if (moment(startDate).isSame(endDate)) { //TASK 3922 - Controlem el cas que la data inici i la data final siguin iguals
            if (startDate > moment()) {                
                startDate = moment();
                StorageService.setObject("DBegin", DateService.toDate(startDate));
                endDate = moment().add('days', 1 * window.DefaultNights);
                StorageService.setObject("DEnd", DateService.toDate(endDate));
            }
        }

        var a = moment(startDate);
        var b = moment(endDate);

        if (b.diff(a, 'days') >= 45) { //TASK3930 - controlem si hi ha mes de 45 dies entre les dues dates 
            startDate = moment();
            StorageService.setObject("DBegin", DateService.toDate(startDate));
            endDate = moment().add('days', 1 * window.DefaultNights);
            StorageService.setObject("DEnd", DateService.toDate(endDate));
        }

        bk.dStart = moment(startDate).format('DD/MM/YYYY');
        bk.dEnd = moment(endDate).format('DD/MM/YYYY');
        bk.dates = bk.dStart +" - " +bk.dEnd;
        writeLog(bk.dates);
      }

      /**
      * Se parsea una fecha para daterangepicker
      * 
      * DBegin: ""2019-08-01T00:00:00.000Z""
      *  DEnd:  ""2019-08-09T00:00:00.000Z""
      */

      bk.parseDates = function()
      {
        if(bk.dates.indexOf("-") > -1  )
        {
          bk.dStart = bk.dates.split('-')[0].trim();
          bk.dEnd = bk.dates.split('-')[1].trim();
          var selDate = DateService.toDate(bk.dStart);
          if (selDate.getTimezoneOffset() > 0) {
              selDate = new Date(selDate.getUTCFullYear(), selDate.getUTCMonth(), selDate.getUTCDate());
          }
          if (isNaN(selDate.getDate())) {
              return;
          }
          StorageService.setObject("DBegin", selDate );
          StorageService.setObject("DEnd", DateService.toDate(bk.dEnd));
        }

      }
      /**
      *Maximo de edad por destinación
      * Hoteles Cuba: niños hasta 12 (0 a 12)
      * Salou: niños hasta 10 (0 a 10)
      * Resto de la cadena: niños hasta 11 (0 a 11)
      */
      bk.setMaxAgeDestination = function () {
          
          var desSelected = bk.destination;

          //Es un Hotel;
          if (bk.getHotelOrDestination(desSelected) == 2)
          {
              desSelected = bk.getDestinationByHotel(desSelected);
          }
          writeLog(desSelected);
          if(!desSelected)
              return 11;
          
            if (desSelected.indexOf("Salou - Cambrils") >= 0) {
                return 10;
            }
            /*if (desSelected.indexOf("Cuba") >= 0) {
                return 12;
            }
            /*else if (desSelected.indexOf("Sevi") >= 0) {
                return 12;
            }*/
            /*else if (desSelected.indexOf("Costa del Sol") >= 0) {
                return 12;
            }*/
            else {
                return 11;
            }
        
      }

      /**
      * GET HOTELS LIST
      */

      bk.getHotelsList = function (deferred)
      {
        if(!deferred)
              deferred = $q.defer();

        if (window.BookingHotelsLoading && window.BookingHotels.length==0) {
        /*    var returnBookingHotels = function (deferred) {
                
                $timeout(function () {
                    if (window.BookingHotels.length)
                        deferred.resolve(window.BookingHotels);
                    else
                        returnBookingHotels(deferred);
                }, 50);
            }

            returnBookingHotels(deferred);*/
            $timeout(function () {
                if (window.BookingHotels.length){
                    bk.hotelsList = window.BookingHotels;
                    deferred.resolve(window.BookingHotels);
                }
                else
                    bk.getHotelsList(deferred);
            }, 50);
            
        } else {

            var currentLanguage = VariableService.language;
            var url = window.ServiceMasterUrl + "/GetHotelsList/" + currentLanguage + "/" + sessionStorage["Cadena"]

            var successCallback = function (data) {
                window.BookingHotelsLoading = false;
                bk.hotelsList = data.data;
                writeLog(bk.hotelsList);
                deferred.resolve(data.data);
            }

            var errorCallback = function (data) {
                window.BookingHotelsLoading = false;
                deferred.reject(data.data);
            }
            window.BookingHotelsLoading = true;
            $http.get(url).then(successCallback, errorCallback);
        }
        deferred.notify();
        return deferred.promise;
      }
      /**
      *
      */
      bk.oneHotelInDestination = function()
      {
        if(bk.hotelsList[bk.destinationIndex].Hotels.length==1)
        {
          bk.setHotel(0);
        }
      }
      /**
      * Hace set a un destino determinado.
      */

      bk.setDestination = function(index)
      {
 
          bk.destinationIndex = index;
          bk.destination = bk.hotelsList[index].Description;
          bk.destinationType = "Hotels";
      }
      bk.getDestination =function(id)
      {
          for (var i = 0; i < bk.hotelsList.length; i++) {
              if(bk.hotelsList[i].IdLocation==id)
              {
                  return bk.hotelsList[i];
              }
          }
          return false;
      }

      /**
      * Devuelve una destinacion no realiza key sensitive
      */
      bk.indexOfDestination = function(d)
      {
        if(!d)
          return null ;

        for (var i = 0; i < bk.hotelsList.length; i++) {
         if(bk.hotelsList[i].Description.toLowerCase() === d.toLowerCase())
          return i ;
        }
        return null;
      }

      /**
      *A partir de un nombre hace set de una destinación o hotel
      */
      bk.hotelOrDestination = function()
      {
        var v = $filter('ucwords')( bk.destination);
        //es una destinación
        if(bk.indexOfDestination(v)!== null)
        {
          bk.setDestination(bk.indexOfDestination(v));
        }
        //es un hotel
        for (var i = 0; i < bk.hotelsList.length; i++) {
          var d = $filter('ucwords')( bk.hotelsList[i].Description );
          if(bk.indexOfHotel(d,v)!== null)
          {
               bk.setDestination(bk.indexOfDestination(d));
               bk.setHotel(bk.indexOfHotel(d,v));
          }
        }
      };
     /**
    *A partir de un nombre de hotel hace get de una destinacion 
    */
      bk.getDestinationByHotel = function (v) {
          var v = $filter('ucwords')(v);
          var r = null;
          for (var i = 0; i < bk.hotelsList.length; i++) {
              var d = $filter('ucwords')(bk.hotelsList[i].Description);
              if (bk.indexOfHotel(d, v) !== null) {
                  r = bk.hotelsList[i].Description;
              }
          }
          return r;
      };

      /**
      *A partir de un nombre hace get de una destinación o hotel
      */
      bk.getHotelOrDestination = function(v)
      {
        var v = $filter('ucwords')( v);
        var r = 0 ;
        //es una destinación
        if(bk.indexOfDestination(v) !== null)
        {
          r = 1;
        }
        //es un hotel
        for (var i = 0; i < bk.hotelsList.length; i++) {
          var d = $filter('ucwords')( bk.hotelsList[i].Description );
          if(bk.indexOfHotel(d,v)!== null)
          {
               r = 2;
          }
        }
        return r;
      };
      

      /**
      * Hace set de un hotel determinado.
      */
      bk.setHotel = function(index)
      {
          writeLog(bk.hotelsList[bk.destinationIndex].Hotels[index])
          if(bk.hotelsList[bk.destinationIndex].Hotels[index].OnlyAdults && bk.getTotalSons() ){
            bk.openModal().then(function(data){
              bk.setTotalSonsToZero();
              bk.hotel = bk.hotelsList[bk.destinationIndex].Hotels[index];
              bk.destination = bk.hotelsList[bk.destinationIndex].Hotels[index].HotelName;  
            });
          }else{
            bk.hotel = bk.hotelsList[bk.destinationIndex].Hotels[index];
            bk.destination = bk.hotelsList[bk.destinationIndex].Hotels[index].HotelName;  
          }
          

      }
      //devuelve un hotel no realiza key sensitive
      bk.indexOfHotel= function(d,h)
      {
        if(!d || !h )
          return null;

        for (var i = 0; i < bk.hotelsList.length; i++) {
          if(bk.hotelsList[i].Description.toLowerCase() === d.toLowerCase())
          {
            for (var j = 0; j < bk.hotelsList[i].Hotels.length; j++) {
              if(bk.hotelsList[i].Hotels[j].CodAbreviado === h  || bk.hotelsList[i].Hotels[j].HotelName.toLowerCase()===h.toLowerCase())
                return j;
             }
          }
        }
        return null;
      }

      /**
      * Reseteamos la destinación
      */

      bk.resetDestination = function()
      {
        bk.hotel = -1;
        bk.destination = "";
        bk.destinationIndex = -1;
        bk.destinationType = "Destinations";
        localStorage["CodHotel"] = -1;
        localStorage["CodLocation"] = -1;

      }
      /**
      * Reseteamos el Hotel
      */

      bk.resetHotel = function()
      {
         bk.hotel = -1;
         localStorage["CodHotel"] = -1;

      }

    	/**	
    	* GEOCODE FUNCTION
    	*/

    	bk.geocode = function(address)
    	{
        return;
    		if(!geocoder)
    			geocoder = new google.maps.Geocoder;

    		if(address.length < 3) return false;
    		geocoder.geocode({'address': address}, function(results, status) {
          		if (status === 'OK') {
            		writeLog(results);
          		} else {
            		alert('Geocode was not successful for the following reason: ' + status);
          		}
        	});
    	};

      //Añade una habitacion
      bk.addRoom = function()
      {
        bk.extras.rooms.push(new room());
      }

    	/**
    	*	modifica los valores de las habitaciones
    	*/
    	bk.update = function (hclass) {
        	
        	var newVal;
        	var oldValue = bk.extras.numberRooms;
        	var maxValue = 4;
        	var minValue = 1;

        	if(!hclass)
        	{
        		if( bk.extras.rooms.length!= bk.extras.numberRooms)
        		{
       				bk.extras.rooms = [];		
        			for (var i = 0; i < bk.extras.numberRooms; i++) {
        				bk.extras.rooms.push(new room());
        			}
        		}

        		return true;
        	}

       		if (hclass == 'btn-plus'){
       		    if (oldValue < maxValue) {
       		      newVal = parseInt(oldValue) + 1;
       		      bk.extras.rooms.push(new room());
       		      writeLog(bk.extras.rooms);
       		    } else {
       		      newVal = oldValue;
       		    }
       		} else if (hclass == 'btn-minus')  {
       		    if (oldValue > minValue) {
       		      newVal = parseInt(oldValue) - 1;
       		      bk.extras.rooms.pop();
       		      writeLog(bk.extras.rooms);
       		    } else {
       		      newVal = minValue;
       		    }
       		}
       		bk.extras.numberRooms = newVal;

       		return true;
    	};

    	bk.updateAdults = function (hclass, key) {
        	
        	var newVal;
        	var oldValue = bk.extras.rooms[key].adults;
        	var maxValue = 6;
        	var minValue = 1;

       		if (hclass == 'btn-plus'){
       		    if (oldValue < maxValue) {
       		      newVal = parseInt(oldValue) + 1;
       		    } else {
       		      newVal = oldValue;
       		    }
       		} else if (hclass == 'btn-minus')  {
       		    if (oldValue > minValue) {
       		      newVal = parseInt(oldValue) - 1;
       		    } else {
       		      newVal = minValue;
       		    }
       		}
       		bk.extras.rooms[key].adults = newVal;
       		

       		return true;
    	};

    	  bk.updateKids = function (hclass, key) {
        	
            var newVal;
        	var oldValue = bk.extras.rooms[key].kids;
        	var maxValue = 4;
        	var minValue = 0;


        	if(!hclass)
        	{
        		if( bk.extras.rooms[key].years.length!= bk.extras.rooms[key].kids)
        		{
       				bk.extras.rooms[key].years = [];		
        			for (var i = 0; i < bk.extras.rooms[key].kids; i++) {
        				bk.extras.rooms[key].years.push("");
        			}
        		}

        		return true;
        	}

       		if (hclass == 'btn-plus'){
       		    if (oldValue < maxValue) {
       		      newVal = parseInt(oldValue) + 1;
       		      bk.extras.rooms[key].years.push("");
       		    } else {
       		      newVal = oldValue;
       		    }
       		} else if (hclass == 'btn-minus')  {
       		    if (oldValue > minValue) {
       		      newVal = parseInt(oldValue) - 1;
       		      bk.extras.rooms[key].years.pop();
       		    } else {
       		      newVal = minValue;
       		    }
       		}
       		bk.extras.rooms[key].kids = newVal;
       		

       		return true;
    	};

    	bk.updateKidsYears = function (hclass, key, kidKey) {
        	
        	var newVal;
        	var oldValue = bk.extras.rooms[key].years[kidKey];
        	var maxValue = bk.setMaxAgeDestination();
        	var minValue = 0;

       		if (hclass == 'btn-plus'){
       		    if (oldValue==="" || oldValue === null) {
                    newVal = 0 ;
       		    }
       		    else if (oldValue < maxValue) {
                    newVal = parseInt(oldValue) + 1;
       		    }
       		    else {
       		          newVal = oldValue;
       		    }
       		} else if (hclass == 'btn-minus')  {
       		    if (oldValue > minValue) {
       		      newVal = parseInt(oldValue) - 1;
       		    }
       		    else {
       		      newVal = minValue;
       		    }
       		}
       		bk.extras.rooms[key].years[kidKey] = newVal;
       		return true;
    	};
      /**
      *
      **/
      bk.kidsValidation = function(form)
      {
          var b = false;
          bk.ageUp = false;
        angular.forEach(bk.extras.rooms, function(room, key) {
            room.years.length
            angular.forEach(room.years, function(year, ykey) {
              var keyStr = "r_hab"+(key+1)+"_nino"+(ykey+1);
              if(form[keyStr])
              {
                if(form[keyStr].$invalid)
                {
                    b = true;
                    var maxAge = bk.setMaxAgeDestination();
                    if (year > maxAge) {
                        bk.ageUp = true;
                    } 
                }
              }
            });
          
        });
        return b;

      }
      

    	/**
    	*	Devuelve el total de adultos
    	*/
    	bk.getTotalAdults = function()
    	{
    		var total = 0 ;
    		for (var i = 0; i < bk.extras.rooms.length; i++) {
    			total = total + bk.extras.rooms[i].adults;
    		}
    		return total;
    	};
      //Devuelve el total de adultos sumando los hijos
      bk.getTotalAdultsWithSons = function()
      {
        var total = 0 ;
        for (var i = 0; i < bk.extras.rooms.length; i++) {
          total = total + bk.extras.rooms[i].adults;
          total = total + bk.extras.rooms[i].kids;
        }
        return total;
      };

      bk.getTotalSons = function()
      {
        var total = 0 ;
        for (var i = 0; i < bk.extras.rooms.length; i++) {
          total = total + bk.extras.rooms[i].kids;
        }
        return total;
      }

      bk.setTotalSonsToZero = function(v)
      {
        
        for (var i = 0; i < bk.extras.rooms.length; i++) {
          bk.extras.rooms[i].kids = 0;
          bk.extras.rooms[i].years = [];
        }
        
      }

      

      /**
      * Validacion del codigo promocional.
      */

      bk.ValidatePromotionalCode = function(pCode, pDateIn, pDateOut, pUserState, pLocation, pHotel, pLanguage) {
          showWaitScreen();
          var ip = getIP();
          var codigoClub = readCookie(window.cookiecodigoclienteclub);
          var ajaxUrl = ServiceRooms + "/PromotionalCodeValidationPost";
          var p = {
              codPromo: pCode,
              codAbreviadoHotel: pHotel,
              codDestino: pLocation,
              dBegin: DateToString(pDateIn, "dd/mm/yyyy"),
              dEnd: DateToString(pDateOut, "dd/mm/yyyy"),
              clubH10State: pUserState,
              language: pLanguage,
              ip: ip
          };
      
          
          /*ajaxUrl = ServiceRooms + "/PromotionalCodeValidation" + '/' + pCode + '/' + pHotel + '/' + pLocation
              + "/" + DateToString(pDateIn) + '/' + DateToString(pDateOut)
              + '/' + pUserState + '/' + pLanguage + '/' + ip+ '/' + codigoClub;*/
          //ajaxUrl = ServiceRooms + "/PromotionalCodeValidation"
      
          //var data = executeAjaxSyncPostFunction(ajaxUrl, p);
          /*SyncronousAjaxLoad(ajaxUrl, function (data) {
              writeLog
(data);
              if (data.length > 0) {
                  result = false;
                  openModalWithHtml($('span[id$=litTituloSeleccionarBuscar]').text(), data);
              }
          });*/
          var result = true;

          return $http({
            method: 'POST',
            url: ajaxUrl,
            data: p,
            }).then(function(data)
            {
              closeWaitScreen();
              writeLog(data.data);
              //Data.data viene con el error escrito !
              if (data.data.length > 0) {
                  result = false;
                  openModalWithHtml($('span[id$=litTituloSeleccionarBuscar]').text(), data.data);
              }
               
            return result;

          }, function (data) {
              //Se ha producido un error 
              closeWaitScreen();
              return false;

          });

          
      }

        /**
        * Funciones de modal 
        */
      bk.BookingModal = false;
      bk.BookingStatus = false;
      var d = null;
      bk.openModal = function()
      {
        d = $q.defer();
        bk.BookingModal = true;
        return d.promise;
      }

      bk.setModal = function (b) {
         writeLog("Resolviendo");
          bk.BookingStatus = b;
          bk.BookingModal = false;
          if(b){
            d.resolve(bk.BookingStatus);
          }else{
            d.reject(bk.BookingStatus);
          }
      }
        /**
         * Final de Modal
         */

    	return bk;
    }
})();