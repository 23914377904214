(function(){
    'use strict';

    angular
    .module('app')
    .factory('LocalStorageService', LocalStorageService);

    LocalStorageService.$inject = ["$timeout"];
    /**
     * Deprecated:  Metodos para localStorage
     */
    function LocalStorageService($timeout)
    {
    	var ls = {};
        /*
         * Se recoge la variable de localStorage
         */
    	ls.getLocalStorage = function()
    	{
    		return localStorage;
    	}
        /**
         * Se crea un valor
         */
    	ls.set = function(name,value)
    	{
    		if(ls.isObject(value))
	    		localStorage.setItem(name, value);
	    	else
	    		localStorage.setObject(name,value)
	    	
    		return localStorage.getItem(name);
    	}
    	/*
    	 * Devuelve un valor del objeto localStorage
    	 */
    	ls.get = function(name,def)
    	{
		    if (localStorage.getItem(name) === null) {
		    	return def;
		    }
		    return localStorage.getItem(name);
    	}
		/**
		*	Valida que es un objeto el valor que llega .
		*/
		ls.isObject = function(val) {
    		return (typeof val === 'object');
		}
        /*
         * Devuelve el objeto ls como funcionalidad extra de localStorage
         */
    	return ls ;
    }

})();
