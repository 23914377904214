(function(){
    'use strict';

    angular
    .module('app')
    .factory('LoginService', LoginService);

    LoginService.$inject = ["$timeout","$http","CookieService","UserService"];

    function LoginService($timeout,$http,CookieService,UserService)
    {
    
    	var login = {};


    	login.H10Login = function(user, pass, type, optional) {

        showWaitScreen();
    		writeLog("H10Login");
    		var optionalParam = checkDataUndefined(optional);
    		var parms = {
    		    numeroTarjeta: user,
    		    contrasena: pass,
    		    type: type,
    		    optional: optionalParam,
    		    format: "json"
    		};
		
    		var ajaxUrl = controllerURL + "?Operation=Login&lcid=" + getCurrentLanguage();
        return $http({
          url: ajaxUrl,
          method: "POST",
          params: parms
        }).then(function(data){
          login.cookieLoginParams( data.data );
          closeWaitScreen();
          var retData = data.data;
          $('#collapseLog .userLog').width($('#mobile-menu .header-nav').width());
            //login correcto
          if (retData.errorProduced == undefined || retData.errorProduced == "") {
              //cerramos collapse para evitar cambio anterior a la carga
              //
              $('#collapseLog').collapse('hide');
              $('#btn-log').removeClass('active');
          }

          return data.data;

        },function()
        {
          closeWaitScreen();
        });
    		//executeAjaxFunction(ajaxUrl, cookieLoginParams, parms, "POST", "json", true, false, true);
		 		writeLog("/H10Login");
    		
		  }

      login.cookieLoginParams = function(retData) {
                
          if (retData.errorProduced !== undefined && retData.errorProduced !== "") {
             // if (retData.errorProduced != "") {
                  closeWaitScreen();
                  UserService.loginError = true;
                  /*messages.CUSTOM_MESSAGE.title = "Login";
                  messages.CUSTOM_MESSAGE.message = retData.errorProduced;
                  showMessage(messages.CUSTOM_MESSAGE);*/
                  UserService.loginErrorMessage = retData.errorProduced;
                  writeLog
("mensaje de error");
                  $timeout(function()
                  {
                      UserService.loginErrorMessage = "";
                      UserService.loginError = false;
                  },4000);
                  return;
              //}
          }
          //CodigoClienteClub --> A LA COOKIE!
          CookieService.createCookie(window.cookieuserdisplayname, retData.NombreCompleto);
          CookieService.createCookie(window.cookieemail, retData.Email);
          CookieService.createCookie(window.userdisplayname, retData.Nombre);
          CookieService.createCookie(window.cookieusername, retData.Name);
          CookieService.createCookie(window.cookieuserlastname, retData.LastName);
          CookieService.createCookie(window.cookieuserlastname2, retData.LastName2);
          CookieService.createCookie(window.cookieuserh10state, retData.StateClubH10);
          CookieService.createCookie(window.cookieusercountry, retData.Country);
          CookieService.createCookie(window.cookieemail, retData.Email);
          CookieService.createCookie(window.cookiephone, retData.Phone);
          CookieService.createCookie(window.cookieuserpostalcode, retData.Phone);      
          CookieService.createCookie(window.cookiecodigoclienteclub, retData.CodigoClienteClub);
          CookieService.createCookie(window.cookiecodigoclub, retData.CodigoCliente);
          CookieService.createCookie(window.cookiecodigoclientefacebook, retData.CodigoClienteFacebook);
          CookieService.createCookie(window.cookieoauth_tokenTwitter, retData.oauth_tokenTwitter);
          CookieService.createCookie(window.cookieoauth_token_secretTwitter, retData.oauth_token_secretTwitter);
          UserService.setUsername(retData.NombreCompleto);
      
          //$(document).trigger('userLoged', '');
          writeLog
(UserService);
          writeLog
("LOGGED");
          writeLog("/cookieLoginParams");
      }

      login.cookieLoginAgenciaParams = function(retData) {
          writeLog("cookieLoginAgenciaParams");

          if (retData.errorProduced != undefined && retData.errorProduced !== "") {
              if (retData.errorProduced != "") {
                  closeWaitScreen();
                  UserService.loginError = true;
                  /*messages.CUSTOM_MESSAGE.title = "Login";
                  messages.CUSTOM_MESSAGE.message = retData.errorProduced;
                  showMessage(messages.CUSTOM_MESSAGE);*/
                  UserService.loginErrorMessage = retData.errorProduced;
                  writeLog
("mensaje de error");
                  $timeout(function () {
                      UserService.loginErrorMessage = "";
                      UserService.loginError = false;
                  }, 4000);

              }
              return false;
          }

              CookieService.deleteCookie('BookingPromotionalCode');
              CookieService.createCookie(window.agencianame, retData.CompanyName);
              CookieService.createCookie(window.agenciacode, retData.CompanyCode);
              CookieService.createCookie(window.agenciadisplayname, retData.NombreCompleto);
              CookieService.createCookie(window.agenciausername, retData.Nombre);
              CookieService.createCookie(window.agenciapassword, retData.Password);
              UserService.setAgencia(retData.NombreCompleto);
          //    return true;
          
          closeCodPromo();
          //Solo modifica textos
          //setAgencianame(retData.NombreCompleto);
      
          //$(document).trigger('agenciaLoged', '');
          writeLog("/cookieLoginAgenciaParams");
          return true;
      }
  

    	login.getH10Login = function(remember) {
   			login.closeSession(true);
   		
      	writeLog("getH10Login");
        if(remember)
          CookieService.setDays(7);
        else 
          CookieService.resetDays();

   			/*var idLog = jQuery("#login").val();
   			var idPass = jQuery("#password").val();

   			if (typeof (pKeepWaitScreenOpen) != 'undefined' && pKeepWaitScreenOpen == true) {
   			    idLog = "";
   			    idPass = "";
   			}

   			if (idLog == "") 
          idLog = jQuery("#login_m").val();
   			if (idPass == "") 
          idPass = jQuery("#pass_m").val();

   			if (idLog == "") 
          idLog = jQuery("#login1").val();
   			if (idPass == "") 
          idPass = jQuery("#password1").val();

   			writeLog(idLog + idPass);
   			if (idLog == "" || idPass == "")
   			    return false;

   			showWaitScreen();

   			//login.H10Login(idLog, idPass, "Login");

   			if (!pKeepWaitScreenOpen) {
            //closeWaitScreen();
   			}*/
   			writeLog("/getH10Login");
   			return true;
		  }
    	//Cerramos la session de un usuario 
    	login.closeSession = function(pSkipTriggerEvent) {
    		CookieService.deleteCookie(window.cookieemail);
    		CookieService.deleteCookie(window.cookieuserdisplayname);
    		CookieService.deleteCookie(window.cookieusername);
    		CookieService.deleteCookie(window.cookieuserlastname);
    		CookieService.deleteCookie(window.cookieuserlastname2);
    		CookieService.deleteCookie(window.cookieuserh10state);
    		CookieService.deleteCookie(window.cookieusercountry);
    		CookieService.deleteCookie(window.cookieemail);
    		CookieService.deleteCookie(window.cookiephone);
    		CookieService.deleteCookie(window.cookieuserpostalcode);
    		CookieService.deleteCookie(window.cookiecodigoclienteclub);
    		CookieService.deleteCookie(window.cookiecodigoclub);
    		CookieService.deleteCookie(window.cookiecodigoclientefacebook);
    		CookieService.deleteCookie(window.cookieoauth_tokenTwitter);
   			CookieService.deleteCookie(window.cookieoauth_token_secretTwitter);

    		if (typeof (pSkipTriggerEvent) != 'undefined' && pSkipTriggerEvent == true) {
        		return;
    		}
        UserService.logout();

    		$(document).trigger('userLogout', '');
    		location.reload(true);
		}
    //Cerramos la session de una agencia 
    login.closeSessionAgencia =function(pSkipTriggerEvent) {
        if (typeof (pSkipTriggerEvent) != 'undefined' && pSkipTriggerEvent == true) {
            return;
        }
        writeLog("closeSessionAgencia");

        CookieService.deleteCookie(window.agencianame);
        CookieService.deleteCookie(window.agenciacode);
        CookieService.deleteCookie(window.agenciadisplayname);
        CookieService.deleteCookie(window.agenciausername);
        CookieService.deleteCookie(window.agenciapassword);
        UserService.logout();

        writeLog("/closeSessionAgencia");
        //$(document).trigger('userLogout', '');
        location.reload(true);
      }


    //LOGIN Agencias
    login.H10LoginAgencia = function(codigo, user, pass, type, optional) {
        writeLog("H10LoginAgencia");
        var optionalParam = checkDataUndefined(optional);
        var parms = {
            codigoEmpresa: codigo,
            user: user,
            contrasena: pass,
            type: type,
            optional: optionalParam,
            format: "json"
        };
    
        var ajaxUrl = controllerURL + "?Operation=LoginAgencia&lcid=" + getCurrentLanguage();
        //executeAjaxFunction(ajaxUrl, cookieLoginAgenciaParams, parms, "POST", "json", true, false, true);
    
        return $http({
              url: ajaxUrl,
              method: "POST",
              params: parms
            }).then(function(data){
                  writeLog
(data.data);
                  writeLog
(data.data.errorProduced);
                  login.cookieLoginAgenciaParams(data.data);
                  //login.cookieLoginParams( data.data );
                  closeWaitScreen();
                  var retData = data.data;
                  $('#collapseLog .userLog').width($('#mobile-menu .header-nav').width());
                  //login correcto
                  if (retData.errorProduced == undefined || retData.errorProduced == "") {
                      //cerramos collapse para evitar cambio anterior a la carga
                      //
                      $('#collapseLog').collapse('hide');
                      $('#btn-log').removeClass('active');
                  }

                  return data.data;
            }, function (response) {
                return response;
            });
        writeLog("/H10LoginAgencia");
        
    }



    	return login ;
    }


})();