(function () {
    'use strict';

    angular
    .module('app')
    .factory('BonoService', BonoService);

    BonoService.$inject = ["$timeout", "$http", "VariableService", "$q"];

    function BonoService($timeout, $http, VariableService, $q) {
        var tm = "?_=1503312658244";
        var bono = {};



        bono.getHotelBanners = function (hotelId) {
            var deferred = $q.defer();

            var ip = VariableService.ip;
            var currentLanguage = VariableService.language;
            var url = window.ServiceHotelUrl + "/GetHotelBanners/" + hotelId + "/" + currentLanguage

            var successCallback = function (data) {
                deferred.resolve(data.data);
            }

            var errorCallback = function (data) {
                deferred.reject(data.data);
            }

            $http.get(url).then(successCallback, errorCallback);

            return deferred.promise;
        }



        bono.getBono = function (id) {
            var deferred = $q.defer();
            
                var ip = VariableService.ip;
                var currentLanguage = VariableService.language;
                var url = window.ServiceRooms + "/getBono/" + currentLanguage + "/" + id

                var successCallback = function (data) {
                    data.data.PersonalDataJson=JSON.parse(data.data.PersonalDataJson);
                    data.data.RequestJSON=JSON.parse(data.data.RequestJSON);

                    deferred.resolve(data.data);
                }

                var errorCallback = function (data) {
                    deferred.reject(data.data);
                }

                $http.get(url).then(successCallback, errorCallback);
            
            return deferred.promise;
        }



        bono.getSumaryData = function (id) {
            var deferred = $q.defer();
            
                var ip = VariableService.ip;
                var currentLanguage = VariableService.language;
                var url = window.ServiceRooms + "/BookingSumaryData/" + currentLanguage + "/" + id + "/" + "true";

                var successCallback = function (data) {
                    deferred.resolve(data.data);
                }

                var errorCallback = function (data) {
                    deferred.reject(data.data);
                }

                $http.get(url).then(successCallback, errorCallback);
            
            return deferred.promise;
        }


        bono.getExpecialServices = function (idHotel) {
            var deferred = $q.defer();
            
                var ip = VariableService.ip;
                var currentLanguage = VariableService.language;
                var url = window.ServiceHotelUrl + "/GetHotelServEsp/" + idHotel + "/" + currentLanguage + "/" + sessionStorage["Cadena"] + "/LogoHome"

                var successCallback = function (data) {

                    deferred.resolve(data.data);
                }

                var errorCallback = function (data) {
                    deferred.reject(data.data);
                }

                $http.get(url).then(successCallback, errorCallback);
            
            return deferred.promise;
        }
        

        return bono;
    }

})();