(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc controller
    * @name BookingController
    * @param {service} $timeout settimeout de angular.
    * @param {service} BookingService Gestiona el sistema de reservas.
    * @param {service} DateService Gestiona las fechas.
    * @param {service} VariableService Gestiona las Variables del sistema.
    * @param {service} StorageService Obtiene y gestiona la variable localStorage.
    * @param {service} $q Implementacion de promesas para angularJS.
    * @param {service} LoginService Obtiene y gestiona el Login de usuario y agencias.    
    * @param {service} UserService Obtiene y gestiona el Usuario desde la REST Api.
    * @param {service} $location Implementacion de windows.location para angularJS.    
    * @description
    * Controlador que gestiona las fechas de entrada, hotel o destino, habitaciones, huespedes y promocodes. (su uso principalmentes esta implementado en Reservas.aspx)
    */
    angular
    .module('app')
    .controller('BookingController', BookingController);

    BookingController.$inject = ["$timeout","BookingService","DateService","VariableService","StorageService","$q","LoginService","UserService","$location"];

    function BookingController($timeout,BookingService,DateService,VariableService,StorageService,$q,LoginService,UserService,$location)
    {
    	writeLog("LOADED BOOKING CONTROLLER");

    	var bk = this;
        /**
        * @property {Bool} open Valora si esta abierto el selector de hoteles y destinos.
        */
        bk.open = false;
    	bk.search = BookingService;
    	bk.DateService = DateService;
        /**
        * @property {Array} hotelsList coleccion de destinos y hoteles.
        */
        bk.hotelsList = [];
        bk.user =  UserService;
        /**
        * Devuelve el numero de hoteles para una destinacion.
        * @memberof BookingController
        * @function getHotelNum
        * @param {dest} Objeto destino.
        */
        bk.getHotelNum = function(dest)
        {
            var num = 0 ; 
            for (var i = 0; i < dest.Hotels.length; i++) {
                if (!dest.Hotels[i].EsBeso)
                {
                    num++;
                }
            }
            return num;
        }
        /**
        * Se deja de usar por la incluson del campo isbeso 
        * @memberof BookingController
        * @function getHotelExcept
        * @param {dest} Objeto destino.
        */
        bk.getHotelExcept = function (dest) {
            var except = ["OEF2", "ORP2"]
            var num = false;
            
            if (except.indexOf(dest) == -1) {
                num = true;
            }
            return num;
        }

        /**
         * Valida si es la primera vez que entramos en el site o no 
         * Se utiliza para la clase reduced del reservas.aspx
         * @memberof BookingController
         * @function access
         */
        bk.access = function () {
            var sesion = sessionStorage.getItem("firstEnter");
            $timeout(function () {
                if (sesion == 'true') {
                    angular.element("body").removeClass('o-hidden');
                } else {
                    sessionStorage.setItem("firstEnter", true);
                    if ($(window).width() + scrollCompensate() < 768) {
                        //angular.element("#reservas").removeClass('reduced');
                    } else {
                        angular.element("body").removeClass('o-hidden');
                    }
                }
            }, 200);

        };
        /**
        * Peticion para la carga de hoteles y destinos.
        * @memberof BookingController
        * @function getHotelsList
        */
        BookingService.getHotelsList().then(function(data)
        {
            bk.hotelsList = data;
            bk.search.discount = sessionStorage['BookingPromotionalCode'];
            //Parece necesario para la compatibilidad del codigo antiguo
            window.BookingHotels = data;
            window.isOnlyAdults = false;
            //seteamos el campo del buscador.
            bk.Ocupation();
            var iDestination = bk.search.indexOfDestination(localStorage["CodLocation"]);
            if(iDestination!==null)
            {
               bk.search.setDestination(iDestination);
               var iHotel = bk.search.indexOfHotel(localStorage["CodLocation"], localStorage["CodHotel"]);
               if(iHotel!==null)
               {
                    bk.search.setHotel(iHotel);
               }
            }

        });
        /**
        * Valida que se envie
        * @memberof BookingController
        * @function setSubmitted
        * @param {form} Objeto de reserva.
        */
        bk.setSubmitted = function(form)
        {
            form.$setSubmitted();
            return true;
        }
        /**
        * Gestiona el objeto de sesion de ocupacion
        * @memberof BookingController
        * @function Ocupation
        */
        bk.Ocupation = function()
        {
            var ocupation = StorageService.getObject("Ocupation");
            if(ocupation)
            {
                var cantHab = ocupation.room.length;
                //Valoramos que no sea 0 la ocupacion si lo es, la ponemos a 1
                bk.search.extras.numberRooms = (cantHab)?cantHab:1;                
                for (var i = 0; i < cantHab ; i++) {
                    if (i) {
                        bk.search.addRoom();
                    }
                    bk.search.extras.rooms[i].adults = ocupation.room[i].adultos;
                    bk.search.extras.rooms[i].kids = ocupation.room[i].ninyos;
                    bk.search.extras.rooms[i].numRoom = ocupation.room[i].numRoom;
                    for (var j = 0; j < ocupation.room[i].edades.length; j++) {
                        bk.search.extras.rooms[i].years[j] = ocupation.room[i].edades[j];
                    }
                }
            }
            

        }

        //Log de la variable bk.search
    	bk.log = function()
    	{
    		writeLog(bk.search);
    	};
        /**
        * Iniciliza la busqueda.
        * @memberof BookingController
        * @function BookingSearchInitialize
        */
        bk.BookingSearchInitialize = function() {
            var deferred = $q.defer();
            deleteCookie('BookingIDParams');
            //deleteCookie('BookingPromotionalCode');
            var ok = true;
            var currentCodPromo = BookingService.discount;
            if (currentCodPromo && currentCodPromo != "") {
                var estadoUserClub = 0;
                if (typeof (readCookie(window.cookiecodigoclienteclub)) != 'undefined' && typeof (readCookie(window.cookieuserh10state)) != 'undefined') {
                    estadoUserClub = readCookie(window.cookieuserh10state);
                }
                BookingService.ValidatePromotionalCode(currentCodPromo, StorageService.getObject("DBegin"), StorageService.getObject("DEnd"), estadoUserClub, localStorage["CodLocation"], localStorage["CodHotel"], getCurrentLanguage()).then(function (data) {
                    ok = data;
                    if (!ok) {
                        sessionStorage['BookingPromotionalCode']="";
                        BookingService.discount = "";
                        //$("#Promocode_1").val("");
                    }
                    writeLog(ok);
                    deferred.resolve(ok);
                }, function (data) {
                    BookingService.discount = "";
                    sessionStorage['BookingPromotionalCode']="";
                    deferred.resolve(false);
                });
        
            }else{
                $timeout(function(){
                    deferred.resolve(ok);
                });
            }
            return deferred.promise;
        }
        /**
        * Genera la url con los parametros de la reserva
        * @memberof BookingController
        * @function getH10ParamsUrl
        */
        bk.getH10ParamsUrl = function(pSckipCreateObject) {

            var params = "&fe=" + DateToString(StorageService.getObject("DBegin"));
            params += "&fs=" + DateToString(StorageService.getObject("DEnd")) +
                (localStorage["CodHotel"] == null ? "" : "&h=" + localStorage["CodHotel"]) +
                (localStorage["CodLocation"] == null ? "" : "&d=" + localStorage["CodLocation"]);
        
            if (sessionStorage['BookingPromotionalCode'] != null && sessionStorage['BookingPromotionalCode'] != "" && sessionStorage['BookingPromotionalCode'] != undefined) {
                params += "&cp=" + sessionStorage['BookingPromotionalCode'];
            }
            else {
                params += "&cp=";
            }
       
            var currentOcupation = new H10Ocupation();
        
            //Seleccion Personalizada
                var i = 0;
                var n = 0;
                var cantHab = 0;
                var cantTotalAdultos = 0;
                var cantTotalNinyos = 0;
                var adultos = 0;
                var cantNinyos = 0;
                var edad = 0;

                sessionStorage["MoreOptions"] = "";
                    cantHab = bk.search.extras.numberRooms;
                    cantTotalAdultos = 0;
                    cantTotalNinyos = 0;
                    params += "&nh=" + cantHab;
                    for (i = 2; i < cantHab + 2; i++) {
                        
                        //var adulSel = selAdultos + (i - 1);
                        //adultos = parseInt($(adulSel).val());
                        adultos = bk.search.extras.rooms[i-2].adults;
                        params += '&h' + (i - 1).toString() + 'Ad=' + adultos;
                        cantTotalAdultos += adultos;
        
                        //Calculo Ninos                    
                        if (!window.isOnlyAdults) {
                            //var ninSel = selNinos + (i - 1);
                            //cantNinyos = parseInt($(ninSel).val());
                            cantNinyos = bk.search.extras.rooms[i-2].kids;
                            
                        }
        
                        params += '&h' + (i - 1).toString() + 'ni=' + cantNinyos;
        
                        currentOcupation.SetAdults(adultos, i - 1);
                        currentOcupation.SetChild(cantNinyos, i - 1);
        
                        //Calculo edad ninos
                        if (cantNinyos > 0) {
                            for (n = 1; n < cantNinyos + 1; n++) {
                                //var edadSel = '#r_hab' + (i - 1) + '_nino' + n;
                                //edad = parseInt($(edadSel).val());
                                edad = bk.search.extras.rooms[i-2].years[n-1];                                
                                params += '&h' + (i - 1).toString() + 'eni' + n + '=' + edad;
                                currentOcupation.SetChildYears(edad, n - 1, i - 1);
                            }
                            cantTotalNinyos += cantNinyos;
                        }
                    }
                    writeLog(currentOcupation);
                sessionStorage["MoreOptions"] = params;
                StorageService.setObject("Ocupation", currentOcupation);
                
            return params;
        }
        /**
        * Implementa el proceso de busqueda
        * @memberof BookingController
        * @function SearchProcedure
        */
        bk.SearchProcedure = function (type) {           
            var j,i;
            writeLog("ENTRANDO EN LA BUSQUEDA " + type);

            switch (type) {
                case 1:
                    //me han llamado desde la ribbon
                    break;
                case 2:
                    //me han llamado desde el modal popup           
                    break;
                case 3:
                    //Mirar si en el destino solo existe un hotel para ponerlo por defecto en el combo                   
                    localStorage["CodLocation"] =  window.BookingHotels[bk.search.destinationIndex].Description;
                    sessionStorage['BookingPromotionalCode'] = bk.search.discount;
                    
                    bk.search.oneHotelInDestination();
                    //me han llamado desde el boton buscar de Haz una reserva
                    if (bk.search.hotel != -1) {
                        writeLog("Hotel SEARCH");
                        var nombreHotel = bk.search.hotel.HotelName;
                        localStorage["CodHotel"]= bk.search.hotel.CodAbreviado; //Set localStorage["CodHotel"] 
                        var urlHotel = ""; 
                        
                        for (i = 0; i < window.BookingHotels.length; i++) {
                            if (window.BookingHotels[i].Description == localStorage["CodLocation"]) {
                                for (j = 0; j < window.BookingHotels[i].Hotels.length; j++) {
                                    if (window.BookingHotels[i].Hotels[j].CodAbreviado == localStorage["CodHotel"]) {
                                        urlHotel = window.BookingHotels[i].Hotels[j].UrlHotel.trim();
                                    }
                                }
                                break;
                            }
                        }
                        
                        bk.getH10ParamsUrl();
                        bk.BookingSearchInitialize().then(function(ok){
                            if (ok){
                                VariableService.GetUrlFriendly('Page_Booking').then(function(urlFriendly)
                                {                                    
                                    document.location.assign(urlHotel + urlFriendly); // + "?" + getH10ParamsUrl();
                                });    
                            }
                            else {
                                writeLog("No ha salido bien la busqueda del codigo promocional.")
                            }
                        });                           
                        
                        SetCookiePromoCode();
                        return;
                    }
                    else {
                        ChangeProfile('all'); //Valorar si es viable desactivar esta funcionalidad. 
                        var today = new Date((new Date).getFullYear(), (new Date).getMonth(), (new Date).getDate());
                        if (StorageService.getObject("DBegin")< today) {
                            openModalWithHtml(translate("TituloSeleccionarBuscar"), translate("SeleccionBuscarFecha"));
                            return;
                        } else {
                            bk.BookingSearchInitialize().then(function(ok){
                                if(ok){
                                    //getCodPromocional();
                                    writeLog('/' + getVariation() + GetUrlFriendly('Page_Encuentra') + "?buscar=S" + bk.getH10ParamsUrl());
                                    document.location = '/' + getVariation() + GetUrlFriendly('Page_Encuentra') + "?buscar=S" +  bk.getH10ParamsUrl()
                                }
                                else {                                    
                                    writeLog("No ha salido bien la busqueda del codigo promocional.");
                                }
                            });
                        }                       
                    }
                    break;
                default:
                    break;
            }
            SetCookiePromoCode();
            $('#booking').prop("title", "1");
        }
    }

})();