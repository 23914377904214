(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc service
    * @name VariableService
    * @param {service} $timeout angular window.setTimeout service
    * @param {service} $http Implementacion de peticiones ajax para angularJS.
    * @param {service} $q Implementacion de promesas para angularJS.
    * @description 
    *   Gestion de las Cookies.
    */
    angular
    .module('app')
    .factory('VariableService', VariableService);

    VariableService.$inject = ["$timeout","$http","$q"];

    function VariableService($timeout,$http,$q)
    {

    	var variables = {
    		ip:null,
    		language:_spPageContextInfo.currentLanguage,
    		languagesLinks:[]
    	}


    	variables.init =  function()
    	{
    		variables.ip = variables.getIP();
    		variables.language = variables.getCurrentLanguage();
    		variables.getVariationPages().then(function(data){
    			variables.languagesLinks = data;
    		});
    	}
    	//  Detecta si sharepoint esta en modo edición
    	variables.isEditMode = function() {
    			var publishingEdit = window.g_disableCheckoutInEditMode,
        		form = document.forms[MSOWebPartPageFormName],
        		input = form.MSOLayout_InDesignMode || form._wikiPageMode;  
    			return !!(publishingEdit || (input && input.value));
		}

		variables.getCadena = function()
		{
			return sessionStorage["Cadena"];
		}

		variables.getVariation = function() {
		    return document.location.pathname.split('/').slice(1, 2).join();
		}

    	variables.getCurrentLanguage = function() {
    		return _spPageContextInfo.currentLanguage;
		}

		variables.getCurrentLangCode = function ()
		{
			return _spPageContextInfo.currentCultureName.trim().split('-')[0];
		}

		variables.getCurrentLocale = function()
		{
			return _spPageContextInfo.currentCultureName;
		}
		
    	variables.getIP = function() {
			try {
			    var ipData = null;
			    if (localStorage.getItem("IpCliente") === null) {
			        ajaxUrl = "https://ipinfo.io/?token=be0af1e43eb9eb";
			        var data;
			        return $http.get().then(function(data){
			        	return data.data
			        });
			        $.ajax({
			            url: ajaxUrl,
			            dataType: 'json',
			            success: function (result) {
			                data = result;
			                if (window.logEnabled) {
			                    writeLog(data);
			                }
			            },
			            error: function () {
			                return "212.92.47.211";
			            },
			            timeout: 500,
			            async: false
			        });

			        if (data.ip != null) {
			            localStorage.setObject("CountryCliente", data.country);
			            localStorage.setObject("IpCliente", data.ip);
			            return data.ip;
			        }
			        else {
			            return "212.92.47.211";
			        }
			    }
			    else {
			        ipData = localStorage.getItem("IpCliente");
			        ipData = ipData.replace(/\"/g, "");
			        return ipData;
			    }
			}
			catch (err) {
			    return "127.0.0.1";
			}
		}

		/**
		* http://sp2013:32940/_vti_bin/H10_WebServices/H10_Home.svc/GetAdventages/3082/H10?_=1503323258355
		*/

		variables.getAdventages = function()
		{
        	var currentLanguage = variables.getCurrentLanguage();
        	var url  = window.ServiceMasterUrl+"/GetAdventages/"+currentLanguage+"/"+sessionStorage["Cadena"]
	
        	var  successCallback = function(data)
        	{
        	  return data.data;
        	}
	
        	var errorCallback = function(data)
        	{
        	  return data.data;
        	}
	
        	return $http.get(url).then(successCallback, errorCallback);
		}

		/**
		* http://sp2013:32940/_vti_bin/H10_WebServices/H10_Home.svc/GetVariationPages
		*/

		variables.getVariationPages = function()
		{
		    var deferred = $q.defer();
		    if (window.variationPages.length) {
		        $timeout(function () {
		            deferred.resolve(window.variationPages);
		        })
		    } else {
		        var currentLanguage = variables.getCurrentLanguage();
		        var url = window.ServiceMasterUrl + "/GetVariationPages"

		        var successCallback = function (data) {
		            deferred.resolve(data.data);
		        }

		        var errorCallback = function (data) {
		            deferred.reject(data.data);
		        }

		        $http.post(url, {
		            cadena: sessionStorage["Cadena"],
		            origin: location.protocol + '//' + location.host,
		            currentPage: decodeURI(location.pathname),
		            clearCache: false
		        }).then(successCallback, errorCallback);
		    }
		    return deferred.promise;
		}
		/**
		*
		* Recupera una url en el idioma determinado.
		*/
		variables.GetUrlFriendly = function(word) {
    		var lcid = variables.getCurrentLanguage();
    		var ajaxUrl = window.SearhSvcUrl + "/GetFriendlyUrl/" + word + "/" + lcid;
    		
    		return $http.get(ajaxUrl).then(function (data) {
    			return data.data;
    		})
		}

		variables.GetTranslate = function(word) {
    		var lcid = variables.getCurrentLanguage();
    		var ajaxUrl = window.SearhSvcUrl + "/GetTranslate/" + word + "/" + lcid;
    		
    		return $http.get(ajaxUrl).then(function (data) {
    			return data.data;
    		})
		}

		variables.GetTranslates = function(words) {
    		var lcid = variables.getCurrentLanguage();
    		var ajaxUrl = window.SearhSvcUrl + "/GetTranslates";
    		
    		return $http.post(ajaxUrl,{Lcid:lcid,Words:words}).then(function(data){
    			writeLog
(data);
    			return data.data;
    		});
		}

		variables.ChangeVariationLanguage = function(value,url) {
    		/*var url = $($('ul.idiomas ul li a').toArray().filter(function (e) {
    		 return $(e).data('locale').toLowerCase() == value.toLowerCase(); 
    		})).data('url');*/

    		if (url.indexOf("http") == -1) {
        		url = url.replace("//", "/");
    		}
    		var qrystring = "";
    		var separador = "";
    		var qs = getQueryStringKeys();
    		for (var i = 0; i < qs.length; i++) {
        		writeLog(qs[i]);
        		if (!qs[i].startsWith("Term")) {
        			if(qs[i].trim()!=""){
            			qrystring += separador + qs[i] + "=" + getParameterByName(qs[i]);
            			separador = "&";
            		}
        		}
    		}
    		if (qrystring != "") {
        		url = url + '?' + qrystring;
    		}
    		//writeLog(url);
    		window.location.assign(window.siteUrl + url);
		}

		variables.init();
		//variables.GetTranslates(["Encuentra","EncuentraTitulo"]);
		return variables;
    }

})();