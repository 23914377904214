(function(){
    'use strict';
    /**
   * @memberof app
   * @ngdoc controller
   * @name OffersController
   * @param {service} $timeout settimeout de angular.
   * @param {service} VariableService Gestiona las Variables del sistema.
   * @param {service} BookingService Gestiona el sistema de reservas.
   * @description
   * Controlador del webPart de HomeOffets.
   */

    angular
    .module('app')
    .controller('OffersController', OffersController);

    OffersController.$inject = ["$timeout", "VariableService", "BookingService"];

    function OffersController($timeout, VariableService, BookingService)
    {
    	var offers = this;
    	offers.countHotels = function(idDestino)
    	{
    	    var destination = BookingService.getDestination(idDestino);
    	    if(destination)
    	    {
    	        return destination.Hotels.length;
    	    }
    	    return "";
    	}
        //Funci�n original de H10 hotels modificada para el nuevo codigo, es una simple redirecci�n a la oferta
        offers.urlReservaOffer = function(urlHotel, codHotel, begDate, destino, TipoDeOferta) {
    		urlHotel = urlHotel.replace(" ", "");
    		urlHotel = new URI(urlHotel).getPath();
    		//document.location = urlHotel.replace(" ", "") + GetUrlFriendly("Page_Booking") + getH10UrlReserva(codHotel, begDate, destino);
    		if(TipoDeOferta == "Oferta destino"){

    			VariableService.GetUrlFriendly('Page_Encuentra').then(function(urlFriendly)
				{
                	document.location = "/"+ VariableService.getVariation() +urlFriendly + offers.getH10UrlReservaNoCodNoDes(begDate,TipoDeOferta,destino);
            	});  

    		}else{
    			VariableService.GetUrlFriendly('Page_Booking').then(function(urlFriendly)
				{
                	document.location = urlHotel.replace(" ", "") + urlFriendly + offers.getH10UrlReservaNoCodNoDes(begDate,TipoDeOferta,destino);
            	});  
    		}
    		 
		}

        //Funci�n original de H10 hotels modificada para el nuevo codigo, es una simple redirecci�n a la oferta

		offers.getH10UrlReservaNoCodNoDes = function(begDate,TipoDeOferta,destino) {

    		var f = new Date(begDate.split("/")[2], begDate.split("/")[1] - 1, begDate.split("/")[0], 0, 0, 0);
    		var fFecha = f.getTime();
    		var dias = 7;

    		fFecha = fFecha + (86400000 * dias);
    		var fechaSalida = new Date(fFecha);
    		///es/Encuentra-tu-hotel?buscar=S&fe=20171020&fs=20171023&h=-1&d=Costa%20del%20Sol&cp=&nh=1&h1Ad=2&h1ni=0
    		if(TipoDeOferta == "Oferta destino" )
    			var url = "?buscar=S&fe=" + DateToString(f) + "&fs=" + DateToString(fechaSalida) + "&h=-1&d="+destino+"&cp=&nh=1&h1Ad=2&h1ni=0";
    		else 
    			var url = "?fe=" + DateToString(f) + "&fs=" + DateToString(fechaSalida) + "&cp=&nh=1&h1Ad=2&h1ni=0";



				

    		return url;
		}

    }



})();