(function(){
    'use strict';
    /**
    * @memberof app
    * @ngdoc controller
    * @name NewsletterController
    * @param {service} $timeout settimeout de angular.
    * @param {service} VariableService Gestiona las Variables del sistema.
    * @description
    * Controlador Webpart HomeNewsletter.
    */

    angular
    .module('app')
    .controller('homewebappcontroller', homewebappcontroller);

    homewebappcontroller.$inject = ["$timeout", "VariableService", "homewebappservice"];

    function homewebappcontroller($timeout, VariableService, homewebappservice)
    {
        var wac = this;

        //definim el link del bot� en funci� del idioma

        wac.lcid = VariableService.getCurrentLanguage();
        wac.idioma = VariableService.getCurrentLangCode().toUpperCase();
        wac.styleIos = "";
        wac.styleAndroid = "";
        wac.stylePhone = "";
        //hauriem de definir un switch? per a passar correctament les urls?
        //com gestionem el tema de les imatges en funci� del idioma?

        switch (wac.idioma) {
            case"":
                wac.styleIos = "IOSBtnEN";
                wac.styleAndroid = "AndroidBtnEN";
                wac.stylePhone = "PhoneEN";
                break;
            case "ES":
                wac.styleIos = "IOSBtnES";
                wac.styleAndroid = "AndroidBtnES";
                wac.stylePhone = "PhoneES";
                break;
            case "EN":
                wac.styleIos = "IOSBtnEN";
                wac.styleAndroid = "AndroidBtnEN";
                wac.stylePhone = "PhoneEN";
                break;
            case "CA":
                wac.styleIos = "IOSBtnCA";
                wac.styleAndroid = "AndroidBtnCA";
                wac.stylePhone = "PhoneES";
                break;
            case "DE":
                wac.styleIos = "IOSBtnDE";
                wac.styleAndroid = "AndroidBtnDE";
                wac.stylePhone = "PhoneEN";
                break;
            case "FR":
                wac.styleIos = "IOSBtnFR";
                wac.styleAndroid = "AndroidBtnFR";
                wac.stylePhone = "PhoneEN";
                break;
            case "IT":
                wac.styleIos = "IOSBtnIT";
                wac.styleAndroid = "AndroidBtnIT";
                wac.stylePhone = "PhoneEN";
                break;
            case "PT":
                wac.styleIos = "IOSBtnPT";
                wac.styleAndroid = "AndroidBtnPT";
                wac.stylePhone = "PhoneEN";
                break;
            default:
                wwac.styleIos = "IOSBtnEN";
                wac.styleAndroid = "AndroidBtnEN";
                wac.stylePhone = "PhoneEN";
                break;
        }
    }

})();