(function () {
    'use strict';
    /**
    * @memberof app
    * @ngdoc directive
    * @name noautocomplete
    * @param {service} $timeout settimeout de angular.
    * @param {service} $parse Compilador de AngularJS.
    * @param {service} $rootScope Scope root del controlador superior.
    * @description
    * Evita el autocompletado de los campos Input.
    */

    angular
    .module('app').directive('noautocomplete', autocomplete);

    autocomplete.$inject = ['$timeout', '$parse', '$rootScope'];

    function autocomplete($timeout, $parse, $rootScope) {
        return {
            restrict: 'A',
            link: function( $scope, el, attr ) {

                el.bind('change', function(e) {

                    e.preventDefault();

                });
             }
        };
    }


})();