(function () {
    'use strict';
    /**
    * @memberof app
    * @ngdoc controller
    * @name BonoController
    * @param {service} $timeout settimeout de angular.
    * @param {service} BonoService Obtiene y gestiona los Bonos desde la REST Api.
    * @description
    * Controlador de la seccion Bono( se utiliza en BonoPrintLayout.ascx).
    */

    angular
    .module('app')
    .controller('BonoController', FooterController);

    FooterController.$inject = ["$timeout", "BonoService", "$location"];

    function FooterController($timeout, BonoService, $location) {
        var vm = this;
        /**
        * @property {Object} bonoInfo Objeto contenedor de un Bono.
        */
        vm.bonoInfo = {};
        vm.sumaryData = {};
        vm.hotel = { images: {}, path: '' };
        vm.especialServices = {}
        /**
        * Obtiene las destinaciones del footer.
        * @memberof BonoController
        * @function getDestinosFooter
        */


        BonoService.getBono(getQueryStringParameter("order")).then(function (data) {
            console.log(data);
            vm.bonoInfo = data;
            try {
                vm.bonoInfo.PersonalDataJson = JSON.parse(vm.bonoInfo.PersonalDataJson);
                vm.bonoInfo.RequestJSON = JSON.parse(vm.bonoInfo.RequestJSON);
                vm.bonoInfo.RequestJSON[0].Apportionment = JSON.parse(vm.bonoInfo.RequestJSON[0].Apportionment);

            } catch (err) {
                console.log(err.message)
            }
            console.log(vm.bonoInfo);
            BonoService.getExpecialServices(vm.bonoInfo.CodHotel).then(function (data) {
                console.log(data);
                vm.especialServices = data;
            });

            BonoService.getSumaryData(getQueryStringParameter("order")).then(function (data) {
                console.log(data);
                vm.sumaryData = data;
            });
            BonoService.getHotelBanners(vm.bonoInfo.HotelID).then(function (data) {
                vm.hotel.images = data;
                vm.hotel.path = cmsSiteUrl;
            });

        });

        writeLog("Bono LOADED");
    }
})();