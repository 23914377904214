(function(){
    'use strict';

    angular
    .module('app')
    .factory('PhonesService', PhonesService);

    PhonesService.$inject = ["$timeout","$http","VariableService","$q"];

    function PhonesService($timeout,$http,VariableService,$q)
    {
    	var tm = "?_=1503312658244";
    	var phones = {};


    	
    	phones.getPhones = function()
    	{
    	    var deferred = $q.defer();
    	    if (window.phones.length) {
    	        $timeout(function () {
    	            deferred.resolve(window.phones);
    	        });
    	    } else {
    	        var ip = VariableService.ip;
    	        var currentLanguage = VariableService.language;
    	        var url = ServiceUrl + "/GetPhones/" + ip + "/" + currentLanguage + "/" + sessionStorage["Cadena"]

    	        var successCallback = function (data) {
    	            deferred.resolve(data.data);
    	        }

    	        var errorCallback = function (data) {
    	            deferred.reject(data.data);
    	        }

    	        $http.get(url).then(successCallback, errorCallback);
    	    }
        	return deferred.promise;
    	}

    	return phones ;
    }

})();